import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./pages/login/Login";
import DefaultLayout from "./layouts/Default";
import { checkToken } from "./pages/login/loginActions";
import ProtectedRoute from "./ProtectedRoute";
import "./index.css";
import "assets/css/material-dashboard-react.css?v=1.6.0";

class AppRoot extends Component {
  componentWillMount() {
    this.props.checkToken();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Login} />
          <ProtectedRoute path="/admin" loggedIn={this.props.login && this.props.login.loggedIn} component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ login }) => {
  return { login };
};

export default connect(
  mapStateToProps,
  {
    checkToken
  }
)(AppRoot);
