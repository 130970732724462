import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";

function ActionOptionsButton(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState();

  const [primaryButton, setPrimaryButton] = useState();
  const [arrowOptions, setArrowOptions] = useState([]);

  useEffect(() => {
    let pbtn,
      arrOpt = [];

    let i,
      len = props.options.length > 0 ?  props.options.length : 0,
      currItem;
    for (i = 0; i < len; i++) {
      currItem = props.options[i];
      arrOpt.push(currItem)
    }

    setPrimaryButton(pbtn);
    setArrowOptions(arrOpt);
  }, [props.options]);

  const handleDisplayMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="flex items-center actionButton">
      {primaryButton && (
        <a
          onClick={(e) => {
            e.stopPropagation();
            if (primaryButton.callBack) primaryButton.callBack();
          }}
          style={{ color: "#0077C5", cursor: "pointer" }}
        >
          <Typography style={{ color: "primary", fontWeight: "bold" }}>
            {primaryButton.label}
          </Typography>
        </a>
      )}

      {arrowOptions.length > 0 ? (
        <>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDisplayMenu(event);
            }}
            className="actionbtnCss"
          >
            <MoreVert />
          </Button>

          <Menu
            elevation={1}
            open={isMenuOpen}
            anchorEl={menuAnchorEl}
            style={{ marginTop: 25 }}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            onBackdropClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {arrowOptions.map((optionObj, aoi) => (
              <MenuItem
                key={aoi}
                onClick={(event) => {
                  event.stopPropagation();
                  if (optionObj.callBack) optionObj.callBack();
                  handleCloseMenu();
                }}
                disabled={optionObj.disabled}
              >
                {optionObj.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ActionOptionsButton;
