import React, { useState } from "react";
import {
  Grid,
  FormControlLabel,
  MenuItem,
  InputLabel,
  DialogContent,
  Checkbox,
  DialogActions,
  FormHelperText,
  Typography,
  Radio,
  RadioGroup,
  Divider,
  Button,
  FormControl,
  Select as MUISelect,
} from "@material-ui/core";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import PropTypes from "prop-types";
import { addResourceToSignuPlanResourcesMutation } from "./signupPlansGQLQuery";
import AmountInput from "../common/AmountInput";
import { useFormik } from "formik";

const animatedComponents = makeAnimated();

const ResourceModal = (props) => {
  const [addResourceToSignuPlanResources] = useMutation(
    addResourceToSignuPlanResourcesMutation
  );
  const [initForm, setInitForm] = useState({
    id: 0,
    resourceName: "",
    resourceType: "ACTIVE_AT_A_TIME",
    price: 0.0,
    cycle: null,
    includedCount: 0,
    addition: 1,
    reflectChange: false,
    maximumCount: 0,
  });

  const formik = useFormik({
    initialValues: initForm,
    validateOnBlur: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: Yup.object().shape({
      resourceName: Yup.string().required("Please select resource name"),
      resourceType: Yup.string().required("Please select a resource type"),
      cycle: Yup.string()
        .nullable()
        .when("resourceType", {
          is: "USAGE_PER_CYCLE",
          then: Yup.string()
            // .trim()
            .required("Please select cycle"),
        }),
      includedCount: Yup.number()
        .min(0, "please enter valid no")
        .typeError("Please enter a valid number")
        .positive("Only positive values allowed"),
      addition: Yup.number()
        .min(1, "Please enter a valid ratio")
        .typeError("Please enter a valid number")
        .positive("Please enter a valid ratio"),
      includedCount: Yup.number()
        .min(0, "Please enter valid no")
        .required("Please enter included count"),
      // .positive("Only positive values allowed"),
      price: Yup.string()
        .min(1, "please enter at least one number")
        .max(14, "limit exceeds"),
      // price:Yup.number().typeError("Please enter a valid number").positive("Only positive values allowed"),
      maximumCount: Yup.number()
        .min(0, "Please enter a valid ratio")
        .required("Please enter included count"),
      // .positive("Only positive values allowed"),
      reflectChange: Yup.boolean(),
    }),
  });
  const handleSubmit = (values) => {
    let resourceData = {};
    resourceData.id = values.id;
    resourceData.signupPlanId = parseInt(props.signupPlanId);
    resourceData.ratio = values.addition;
    resourceData.resetCycle = values.cycle;
    resourceData.includedCount = values.includedCount;
    resourceData.maxCount = values.maximumCount;
    resourceData.pricePerAdditionalCount = values.price;
    resourceData.resourceName = values.resourceName;
    resourceData.resourceType = values.resourceType;
    resourceData.reflectChange = values.reflectChange;

    if (values.id > 0) {
      //code for Edit
    } else {
      addResourceToSignuPlanResources({
        variables: { signupPlanResourcedata: resourceData },
      })
        .then((item) => {
          if (
            item &&
            item.data &&
            item.data.addResourceToSignuPlanResources &&
            item.data.addResourceToSignuPlanResources.id
          ) {
            if (props.onSuccess) {
              props.onSuccess();
            }
            props.handleClose();
          }
          console.log(item);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };
  const { classes } = props;
  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent style={{ overflow: "hidden", paddingTop: 0 }}>
        <Grid container spacing={2} style={{ marginTop: 4 }}>
          <Typography style={{ fontWeight: "bold" }}>Included</Typography>
          <Grid item xs={12} style={{ zIndex: 99999 }} className="p-0">
            <FormControl
              style={{ display: "flex" }}
              variant="outlined"
              className="w-full"
              size="small"
              required
              error={
                formik.touched.resourceName && formik.errors.resourceName
                // ||
                //  this.state.showDuplicateError
              }
            >
              <InputLabel
                id="demo-simple-select-label"
                className={classes.lableOutline}
              >
                Select resource name
              </InputLabel>
              <MUISelect
                value={formik.values.resourceName}
                className="display-flex w-full"
                fullWidth={true}
                variant="outlined"
                placeholder="resourceName"
                label="resourceName"
                onChange={(e) => {
                  formik.setFieldValue("resourceName", e.target.value);
                  if (
                    e.target.value === "CUSTOMER" ||
                    e.target.value === "USER" ||
                    e.target.value === "SHIFT" ||
                    e.target.value === "WAREHOUSE" ||
                    e.target.value === "WAREHOUSE_LOCATION"
                  ) {
                    formik.setFieldValue("resourceType", "ACTIVE_AT_A_TIME");
                    formik.setFieldValue("cycle", null);
                  } else if (e.target.value === "EMAIL" || e.target.value === "SALES") {
                    formik.setFieldValue("resourceType", "USAGE_PER_CYCLE");
                    formik.setFieldValue("cycle", null);
                  }
                  formik.setFieldValue("addition", 1);
                  formik.setFieldValue("price", 0);
                  formik.setFieldValue("includedCount", 0);
                  formik.setFieldValue("maximumCount", 0);
                  // this.setState({
                  //   duplicateMsg: "",
                  //   showDuplicateError: false,
                  // });
                }}
              >
                <MenuItem value={"CUSTOMER"}>Customer</MenuItem>
                <MenuItem value={"EMAIL"}>Email</MenuItem>
                <MenuItem value={"USER"}>User</MenuItem>
                <MenuItem value={"SALES"}>Sales</MenuItem>
                <MenuItem value={"SHIFT"}>Shift</MenuItem>
                <MenuItem value={"WAREHOUSE"}>Warehouse</MenuItem>
                <MenuItem value={"WAREHOUSE_LOCATION"}>Warehouse Location</MenuItem>
              </MUISelect>
              {formik.errors.resourceName && formik.touched.resourceName ? (
                // this.state.showDuplicateError
                <FormHelperText>
                  {formik.errors.resourceName &&
                    formik.touched.resourceName &&
                    formik.errors.resourceName
                  // ||
                  // (this.state.showDuplicateError &&
                  //   this.state.duplicateMsg)}
                  }
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} className="flex items-center">
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6} className="position-r">
                <FormControl component="fieldset" style={{ padding: 0 }}>
                  <RadioGroup
                    aria-label={"Resource Type"}
                    name="resourceType"
                    color="primary"
                    value={formik.values.resourceType}
                    onChange={(e) => {
                      formik.setFieldValue("resourceType", e.target.value);
                      if (e.target.value === "ACTIVE_AT_A_TIME") {
                        formik.setFieldValue("addition", 1);
                        formik.setFieldValue("cycle", "");
                      } else if (e.target.value === "USAGE_PER_CYCLE") {
                        formik.setFieldValue("addition", 0);
                      }
                    }}
                    className="radioGroupCss flex flex-row items-center justify-between oneRowList"
                  >
                    <FormControlLabel
                      disabled={
                        formik.values.resourceName === "CUSTOMER" ||
                        formik.values.resourceName === "USER" ||
                        formik.values.resourceName === "EMAIL" ||
                        formik.values.resourceName === "SHIFT" ||
                        formik.values.resourceName === "WAREHOUSE" ||
                        formik.values.resourceName === "WAREHOUSE_LOCATION" ||
                        formik.values.resourceName === "SALES"
                      }
                      value="ACTIVE_AT_A_TIME"
                      control={<Radio color="primary" />}
                      label={"Activate at a time"}
                    />
                    <FormControlLabel
                      disabled={
                        formik.values.resourceName === "CUSTOMER" ||
                        formik.values.resourceName === "USER" || 
                        formik.values.resourceName === "SHIFT" ||formik.values.resourceName === "WAREHOUSE" ||
                        formik.values.resourceName === "WAREHOUSE_LOCATION" 
                      }
                      value="USAGE_PER_CYCLE"
                      control={<Radio color="primary" />}
                      label={"Usage per cycle"}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formik.values.resourceType === "USAGE_PER_CYCLE" && (
                <Grid
                  item
                  xs={5}
                  style={{
                    alignSelf: "flex-end",
                    alignItems: "center",
                  }}
                  className="position-r"
                >
                  <FormControl
                    style={{ display: "flex" }}
                    variant="outlined"
                    className="w-full"
                    size="small"
                    error={formik.errors.cycle && formik.touched.cycle}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.lableOutline}
                    >
                      Select cycle name *
                    </InputLabel>
                    <MUISelect
                      value={formik.values.cycle}
                      className="display-flex w-full"
                      fullWidth={true}
                      variant="outlined"
                      error={
                        formik.errors.cycle && formik.touched.cycle
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        formik.setFieldValue("cycle", e.target.value);
                      }}
                    >
                      <MenuItem value={"DAILY"}>Daily</MenuItem>
                      <MenuItem value={"Weekly"}>Weekly</MenuItem>
                      <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
                      <MenuItem value={"YEARLY"}>Yearly</MenuItem>
                    </MUISelect>
                    {formik.errors.cycle && formik.touched.cycle ? (
                      <FormHelperText
                        style={{
                          color: "#F44336",
                          wordBreak: "break-word",
                          marginLeft: 15,
                        }}
                      >
                        {formik.errors.cycle &&
                          formik.touched.cycle &&
                          formik.errors.cycle}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <AmountInput
              id="includedCount"
              name="includedCount"
              fixDigits={true}
              label={"Included count"}
              value={formik.values.includedCount}
              onBlur={(value) => {
                let val = value || 0;
                formik.setFieldValue("includedCount", val);
                // this.setState({
                //   setIsUserEnteredAmount: true,
                // });
              }}
              helperText={
                formik.errors.includedCount &&
                formik.touched.includedCount &&
                formik.errors.includedCount
              }
              error={
                formik.errors.includedCount && formik.touched.includedCount
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider light={false} className="my-5" />
          </Grid>
          <Typography style={{ fontWeight: "bold" }}>Additional</Typography>
        </Grid>
        <Grid direction="row" container spacing={2}>
          <Grid style={{ marginTop: 10 }} item xs={4}>
            <AmountInput
              id="price"
              name="price"
              fixDigits={true}
              label={"Price"}
              value={formik.values.price}
              onBlur={(value) => {
                let val = value || 0;
                formik.setFieldValue("price", val);
              }}
            />
          </Grid>
          {formik.values.resourceType === "USAGE_PER_CYCLE" && (
            <>
              <div style={{ marginTop: 22 }}>
                <Typography variant="h5"> / </Typography>{" "}
              </div>
              <Grid style={{ marginTop: 10 }} item xs={4}>
                <AmountInput
                  id="addition"
                  name="addition"
                  fixDigits={false}
                  label={"Additional"}
                  value={formik.values.addition}
                  onBlur={(value) => {
                    let val = value || 0;
                    formik.setFieldValue("addition", val);
                  }}
                  helperText={
                    formik.errors.addition &&
                    formik.touched.addition &&
                    formik.errors.addition
                  }
                  error={
                    formik.errors.addition && formik.touched.addition
                      ? true
                      : false
                  }
                />
              </Grid>
            </>
          )}
          <Grid style={{ marginTop: 10, alignSelf: "center" }} item xs={3}>
            <Typography>
              {(formik.values.resourceName &&
                formik.values.resourceName === "CUSTOMER" ||
              formik.values.resourceName === "USER" ||  
              formik.values.resourceName === "SHIFT" || 
              formik.values.resourceName === "WAREHOUSE" ||
              formik.values.resourceName === "WAREHOUSE_LOCATION" )
                ? "Per"
                : ""}{" "}
              {" " + formik.values.resourceName &&
                formik.values.resourceName.charAt(0) +
                  formik.values.resourceName.slice(1).toLowerCase().replace("_"," ")}
            </Typography>
          </Grid>
        </Grid>
        <Grid direction="row" style={{ marginTop: 13 }} container spacing={2}>
          <Grid item xs={5}>
            <AmountInput
              id="maximumCount"
              name="maximumCount"
              fixDigits={false}
              label={"Maximum count"}
              value={formik.values.maximumCount}
              onBlur={(value) => {
                let val = value || 0;
                formik.setFieldValue("maximumCount", val);
                // this.setState({
                //   setIsUserEnteredAmount: true,
                // });
              }}
              helperText={
                formik.errors.maximumCount &&
                formik.touched.maximumCount &&
                formik.errors.maximumCount
              }
              error={
                formik.errors.maximumCount && formik.touched.maximumCount
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className="chekBoxLabelActive"
              control={
                <Checkbox
                  name="Active"
                  onChange={(e) => {
                    formik.setFieldValue("reflectChange", e.target.checked);
                  }}
                  color="primary"
                  className="checkBoxprimary"
                />
              }
              label={
                "Add this changes to all the active" +
                " " +
                (props.planName || "") +
                " " +
                "plan customers."
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            // disabled={isSubmitting}
            onClick={formik.handleSubmit}
            color="primary"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </form>
  );
};

ResourceModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const styles = (theme) => ({
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  panel: {
    position: "fixed",
    width: 360,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    top: 0,
    height: "100%",
    minHeight: "100%",
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    padding: "55px 0px 0px",
    transform: "translate3d(360px,0,0)",
    // overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      transform: "translate3d(360px,0,0)",
      boxShadow: "none",
      "&.opened": {
        boxShadow: theme.shadows[5],
      },
    },
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    "&.opened": {
      transform: "translateX(0)",
    },
  },
});

export default withStyles(styles)(connect(mapStateToProps)(ResourceModal));
