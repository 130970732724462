import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardContent,
  Tabs,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  IconButton,
  Icon,
  ListItemText,
  Select as MUISelect,
  Grid,
} from "@material-ui/core";
import { useQuery } from "react-apollo";
import { compose } from "recompose";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, connect } from "react-redux";
import moment from "moment";
import PriceList from "./PriceList";
import ResourceList from "./ResourceList";
import ModuleList from "./ModuleList";
import { industryQuery } from "../plans/signupPlansGQLQuery";
import {
  signupPlanDetailsByIdRootQuery,
  signupPlanModuleBySignupPlanIdRootQuery,
  signupPlanResourcesBySignupPlanIdRootQuery,
} from "./signupPlansGQLQuery";
import ModuleModal from "./ModuleModal";
import Spinner from "../common/Spinner";
import { v4 as uuidv4 } from "uuid";
import PriceModal from "./PriceModal";
import ResourceModal from "./ResourceModal";
import ClientList from "./ClientList";
import { systemClientByPlanIdRootQuery } from "../clients/clientGqlQuery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

const defaultSorted = [
  {
    id: "module_name",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;

const PlansDetails = (props) => {
  const {
    data: signupPlanModuleBySignupPlanIdData,
    loading: signupPlanModuleBySignupPlanIdDataLoading,
    refetch: signupPlanModuleBySignupPlanIdDataRefetch,
    error: signupPlanModuleBySignupPlanIdDataError,
    fetchMore: signupPlanModuleBySignupPlanIdDataFetchMore,
  } = useQuery(signupPlanModuleBySignupPlanIdRootQuery, {
    variables: {
      signupPlanId: parseInt(props.match.params.Id),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    fetchPolicy: "network-only",
  });

  let signupPlanModuleListBySignupPlanIdRootQueryRefetchOnCall = () => {};

  let signupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall = () => {};

  let signupPlanPriceListBySignupPlanIdRefetchOnCall = () => {};

  let systemClientByPlanIdRefetchOnCall = () => {};

  const {
    data: signupPlanDetailsByIdRootQueryData,
    loading: signupPlanDetailsByIdRootQueryDataLoading,
    refetch: signupPlanDetailsByIdRootQueryDataRefetch,
    error: signupPlanDetailsByIdRootQueryDataError,
    fetchMore: signupPlanDetailsByIdRootQueryFetchMore,
  } = useQuery(signupPlanDetailsByIdRootQuery, {
    variables: {
      signupPlanId: parseInt(props.match.params.Id),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      signupPlanDetailsByIdRootQueryData &&
      signupPlanDetailsByIdRootQueryData.signupPlanDetailsById
    ) {
      setPlanDetail(signupPlanDetailsByIdRootQueryData.signupPlanDetailsById);
    } else {
      setPlanDetail([]);
    }
    if (
      signupPlanModuleBySignupPlanIdData &&
      signupPlanModuleBySignupPlanIdData.signupPlanModuleBySignupPlanId &&
      signupPlanModuleBySignupPlanIdData.signupPlanModuleBySignupPlanId
        .signupPlanModules
    ) {
      setModuleList(
        signupPlanModuleBySignupPlanIdData.signupPlanModuleBySignupPlanId
          .signupPlanModules
      );
    } else {
      setModuleList([]);
    }
  }, [signupPlanDetailsByIdRootQueryData, signupPlanModuleBySignupPlanIdData]);

  const [planDetail, setPlanDetail] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPriceDialog, setOpenPriceDialog] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [openResourceDialog, setOpenResourceDialog] = useState(false);
  const [signuPlanDataVersion, setSignuPlanDataVersion] = useState(uuidv4());
  const [
    systemClientSubscriptionData,
    setSystemClientSubscriptionData,
  ] = useState({});
  const [selectedTab, setSelectedTab] = useState("modules");

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };
  var height = window.innerHeight - 195;

  const goBack = () => {
    props.history.goBack();
    return true;
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenResourceDialog(false);
    setOpenPriceDialog(false);
  };
  const handleClickAddModuleOpen = () => {
    setOpenDialog(true);
  };

  const handleClickAdResourceDialogOpen = () => {
    setOpenResourceDialog(true);
  };

  const handleClickAddPricePerSignupPlanDialogOpen = () => {
    setOpenPriceDialog(true);
    // this.setState({
    //   openPriceeDialog: true,
    //   edit: false,
    //   labelEdit: false,
    //   dataEdit: false,
    //   titleName: "Add Price",
    // });
  };
  const { classes } = props;

  return (
    <>
      <div>
        <IconButton
          style={{
            color: "#387DFF",
            zIndex: 9999,
            top: 9,
            position: "absolute",
          }}
          onClick={goBack}
        >
          <Icon fontSize="small">arrow_back</Icon>
        </IconButton>
      </div>
      <div style={{ height: height }}>
        <Grid direction="column" container spacing={2}>
          {signupPlanDetailsByIdRootQueryDataLoading && <Spinner />}
          <Grid item xs={12}>
            <Card className="cardContentWraper">
              <CardContent>
                <Grid container direction="row" spacing={2}>
                  {planDetail && (
                    <Grid container spacing={2}>
                      <Grid xs={4}>
                        <ListItemText
                          className="cardListItem"
                          primary={
                            <Typography
                              variant="caption"
                              className="cardItemprimary"
                            >
                              Plan Name
                            </Typography>
                          }
                          secondary={
                            <Typography className="cardItemsecondary">
                              {planDetail && planDetail.planName}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid xs={4}>
                        <ListItemText
                          className="cardListItem"
                          primary={
                            <Typography
                              variant="caption"
                              className="cardItemprimary"
                            >
                              Industry
                            </Typography>
                          }
                          secondary={
                            <Typography className="cardItemsecondary">
                              {planDetail &&
                                planDetail.industryDetail &&
                                planDetail.industryDetail.industryDisplayName}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid xs={4}>
                        <ListItemText
                          className="cardListItem"
                          primary={
                            <Typography
                              variant="caption"
                              className="cardItemprimary"
                            >
                              Published
                            </Typography>
                          }
                          secondary={
                            planDetail.isPublished ? (
                              <Typography className="cardItemsecondary">
                                Yes
                              </Typography>
                            ) : (
                              <Typography className="cardItemsecondary">
                                No
                              </Typography>
                            )
                          }
                        />
                      </Grid>
                      <Grid xs={4}>
                        <ListItemText
                          className="cardListItem"
                          primary={
                            <Typography
                              variant="caption"
                              className="cardItemprimary"
                            >
                              Price
                            </Typography>
                          }
                          secondary={
                            <div style={{ display: "flex" }}>
                              <Typography className="cardItemsecondary">
                                {planDetail &&
                                  planDetail.signupPlanPriceList &&
                                  planDetail.signupPlanPriceList
                                    .filter(
                                      (j) => j.commitment === "NO COMMITMENT"
                                    )
                                    .map(
                                      (i, idx) =>
                                        "$ " +
                                        i.price +
                                        " " +
                                        i.renewal.charAt(0) +
                                        i.renewal.slice(1).toLowerCase()
                                    )
                                    .join(" / ")}
                              </Typography>
                            </div>
                          }
                        />
                      </Grid>
                      <Grid xs={4}>
                        <ListItemText
                          className="cardListItem"
                          primary={
                            <Typography
                              variant="caption"
                              className="cardItemprimary"
                            >
                              Created Date Time
                            </Typography>
                          }
                          secondary={
                            <Typography className="cardItemsecondary">
                              {moment(planDetail.createdDatetime)
                                .tz("America/New_York")
                                .format("MM-DD-YYYY HH:mm:ss")}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              className="cardContentWraper tabsWraper"
              style={{ maxHeight: height - 10 }}
            >
              <CardContent>
                <div className="tabsWraperHeader">
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off"
                    style={{ padding: 0 }}
                    className="settingTabsHeaderTitle"
                  >
                    <Tab value="modules" label="Modules" />
                    <Tab value="resources" label="Resources" />
                    <Tab value="price" label="Price" />
                    <Tab value="clients" label="Clients" />
                  </Tabs>

                  <div>
                    {selectedTab === "modules" && (
                      <Button
                        variant="outlined"
                        onClick={handleClickAddModuleOpen}
                        className="primaryButton"
                      >
                        <Typography style={{ fontSize: 14, color: "white" }}>
                          Add Module
                        </Typography>
                      </Button>
                    )}
                    {selectedTab === "resources" && (
                      <Button
                        variant="outlined"
                        onClick={handleClickAdResourceDialogOpen}
                        className="primaryButton"
                      >
                        <Typography style={{ fontSize: 14, color: "white" }}>
                          Add Resource
                        </Typography>
                      </Button>
                    )}
                    {selectedTab === "price" && (
                      <Button
                        variant="outlined"
                        onClick={handleClickAddPricePerSignupPlanDialogOpen}
                        className="primaryButton"
                      >
                        <Typography style={{ fontSize: 14, color: "white" }}>
                          Add price
                        </Typography>
                      </Button>
                    )}
                  </div>
                </div>
                <TabPanel value={selectedTab} index="modules">
                  <div style={{ position: "relative" }} className="p-16">
                    <ModuleList
                    bindSignupPlanModuleListBySignupPlanIdRootQueryRefetchOnCall={async (
                      ev
                    ) => {
                      signupPlanModuleListBySignupPlanIdRootQueryRefetchOnCall = ev;
                    }}
                      signuPlanDataVersion={signuPlanDataVersion}
                      signupPlanId={props.match.params.Id}
                      height={height - 220}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={selectedTab} index="resources">
                  <div style={{ position: "relative" }} className="p-16">
                    <ResourceList
                      bindSignupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall={async (
                        ev
                      ) => {
                        signupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall = ev;
                      }}
                      signuPlanDataVersion={signuPlanDataVersion}
                      signupPlanId={props.match.params.Id}
                      height={height - 220}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={selectedTab} index="price">
                  <div style={{ position: "relative" }} className="p-16">
                    <PriceList
                      bindSignupPlanPriceListBySignupPlanIdRefetchOnCall={(
                        ev
                      ) => {
                        signupPlanPriceListBySignupPlanIdRefetchOnCall = ev;
                      }}
                      signuPlanDataVersion={signuPlanDataVersion}
                      signupPlanId={props.match.params.Id}
                      height={height - 220}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={selectedTab} index="clients">
                  <div style={{ position: "relative" }} className="p-16">
                    <ClientList
                      bindSystemClientByPlanIdRefetchOnCall={(
                        ev
                      ) => {
                        systemClientByPlanIdRefetchOnCall = ev;
                      }}
                      signuPlanDataVersion={signuPlanDataVersion}
                      planId={props.match.params.Id}
                      height={height - 220}
                    />
                  </div>
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog
          fullWidth={true}
          scroll={"paper"}
          maxWidth="sm"
          classes={{
            paper: "popoverOverflow",
          }}
          disableBackdropClick
          open={openDialog}
          onClose={handleClose}
        
        >
          <DialogTitle
          // className="popoverOverflow"
            // className={dialogTitle}
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                Add Module
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent 
          classes={{
            root: "popoverOverflow",
          }}                  
          >
            <ModuleModal
              onSuccess={() => {
                signupPlanModuleListBySignupPlanIdRootQueryRefetchOnCall();
                signupPlanModuleBySignupPlanIdDataRefetch();
                signupPlanDetailsByIdRootQueryDataRefetch();
                setSignuPlanDataVersion(uuidv4());
              }}
              props={props}
              planName={planDetail && planDetail.planName}
              signuPlanDataVersion={signuPlanDataVersion}
              moduleIds={moduleList}
              handleClose={handleClose}
              signupPlanId={props.match.params.Id}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth={true}
          scroll={"paper"}
          maxWidth="sm"
          style={{ overflow: "hidden" }}
          disableBackdropClick
          open={openResourceDialog}
          onClose={handleClose}
        >
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                Add Resources
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <ResourceModal
              onSuccess={() => {
                signupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall();
                setRefetch(true);
                setSignuPlanDataVersion(uuidv4());
              }}
              signuPlanDataVersion={signuPlanDataVersion}
              planName={planDetail && planDetail.planName}
              handleClose={handleClose}
              signupPlanId={props.match.params.Id}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth={true}
          scroll={"paper"}
          maxWidth="xs"
          style={{ overflow: "hidden" }}
          disableBackdropClick
          open={openPriceDialog}
          onClose={handleClose}
        >
          <DialogTitle
            // className={classes.dialogTitle}
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                Add Price
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <PriceModal
              edit={false}
              planName={planDetail && planDetail.planName}
              onSuccess={() => {
                signupPlanModuleBySignupPlanIdDataRefetch();
                signupPlanDetailsByIdRootQueryDataRefetch();
                setSignuPlanDataVersion(uuidv4());                
                signupPlanPriceListBySignupPlanIdRefetchOnCall();
                // this.props.signupPlanDetailsByIdRootQuery
                //   .refetch()
                //   .then((data) => {
                //     this.setState({
                //       planDetail: this.props.signupPlanDetailsByIdRootQuery
                //         ? this.props.signupPlanDetailsByIdRootQuery
                //             .signupPlanDetailsById
                //         : [],
                //     });
                //   })
                //   .catch((err) => console.log(err));
                // this.setState({
                //   transectionDataVersion: uuidv4(),
                // });
              }}
              signuPlanDataVersion={signuPlanDataVersion}
              handleClose={handleClose}
              signupPlanId={props.match.params.Id}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

PlansDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps)(PlansDetails);
