import gql from "graphql-tag";

export const sbizzlMaintenanceByFilterAndPaginationQuery = gql`
  query sbizzlMaintenanceByFilterAndPaginationQuery ($checkCompletedMaintanance:Boolean,$limit: Int, $offset: Int) {
    sbizzlMaintenanceByFilterAndPagination (checkCompletedMaintanance:$checkCompletedMaintanance,limit: $limit, offset: $offset) {
      sbizzlMaintenanceList{
        id
        fromDatetime
        toDatetime
        isCompleted
        isActive
        note
      }
      rowCount
    }
  }
`;

export const addSbizzlMaintenanceMutation = gql`
  mutation addSbizzlMaintenance($sbizzlMaintenance: SbizzlMaintenanceInputType) {
    addSbizzlMaintenance(sbizzlMaintenance: $sbizzlMaintenance) {
      id
      fromDatetime
      toDatetime
      isCompleted
      isActive
      note
    }
  }
`;

export const editSbizzlMaintenanceMutation = gql`
  mutation editSbizzlMaintenance($sbizzlMaintenance: SbizzlMaintenanceInputType) {
    editSbizzlMaintenance(sbizzlMaintenance: $sbizzlMaintenance) {
      id
      fromDatetime
      toDatetime
      isCompleted
      isActive
      note
    }
  }
`;

export const deleteSbizzlMaintenanceMutation = gql`
  mutation deleteSbizzlMaintenance($sbizzlMaintenanceId: Int) {
    deleteSbizzlMaintenance(sbizzlMaintenanceId: $sbizzlMaintenanceId) {
      id
      fromDatetime
      toDatetime
      isCompleted
      isActive
      note
    }
  }
`;

export const updateSbizzlMaintenanceCompletedMutation = gql`
  mutation updateSbizzlMaintenanceCompleted($id: Int) {
    updateSbizzlMaintenanceCompleted(id: $id) {
      id
    }
  }
`;
