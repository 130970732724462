import React, { Component } from "react";
import {
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  MenuItem,
  InputLabel,
  DialogContent,
  Checkbox,
  DialogActions,
  FormHelperText,
  Button,
  FormControl,
  Select as MUISelect,
} from "@material-ui/core";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import Select from "react-select";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { FieldArray, Form, Formik, getIn } from "formik";
import { addSuperAdminUserMutation } from "./usersGQLQuery";

const animatedComponents = makeAnimated();
class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleIds: [],
      duplicateMsg: "",
      showDuplicateError: false,
      emailStatus: "Pandding",
      initForm: {
        id: 0,
        email: "",
      },
    };
  }

  handleSubmit = (values) => {
    let userData = {};
    userData.id = values.id;
    userData.primaryEmail = values.email ? values.email.trim() : values.email;    
    if (values.id > 0) {
      //code for Edit
    } else {
      this.props
        .addSuperAdminUserMutation({
          variables: { userData },
        })
        .then((item) => {          
          if (this.props.onSuccess) {
            this.props.onSuccess(
              item && item.data && item.data.addSuperAdminUser.id
            );
          }
          this.props.handleClose();
        })
        .catch((error) => {
            console.log(error)
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].message == "DUPLICATE"
          ) {
            this.setState({
              loading: false,
              showDuplicateError: true,
              duplicateMsg: "email already exists",
            });
          }
        });
    }
  };

  render() {
    const { classes } = this.props;
    const colourStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //   console.log({ data, isDisabled, isFocused, isSelected },styles);
        return {
          ...styles,
          backgroundColor: isFocused ? "#999999" : null,
          color: "#333333",
        };
      },
    };

    return (
      <Formik
        initialValues={this.state.initForm}
        validateOnBlur={false}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          id: Yup.number(),
          email: Yup.string()
            .required("Please enter email")
            .email("Please enter valid email"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent
                classes={
                  {
                    //   root: classes.dialogContentRoot,
                  }
                }
                style={{ paddingTop: 0, zIndex: 9 }}
              >
                <Grid container spacing={2} style={{ marginTop: 4 }}>
                  <Grid item xs={10}>
                    <TextField
                      disabled={this.props.edit}
                      id="email"
                      label={"Email"}
                      type="text"
                      name="email"
                      className="w-full"
                      value={values.email}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      variant="outlined"
                      size="small"
                      error={
                        (touched.email && errors.email) ||
                        this.state.showDuplicateError
                      }
                      helperText={
                        (errors.email && touched.email && errors.email) ||
                        (this.state.showDuplicateError &&
                          this.state.duplicateMsg)
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} className="flex items-center"> */}
                  {/* <Grid container item xs={10}> */}
                  {/* <FormControlLabel
                        control={
                          <Checkbox
                            name="Active"
                            onChange={(e) => {
                              setFieldValue("isPublished", e.target.checked);
                            }}
                            color="primary"
                            className="checkBoxprimary"
                          />
                        }
                        label={"Activate this plan?"}
                      /> */}
                  {/* </Grid> */}
                  {/* </Grid> */}
                </Grid>
              </DialogContent>
              <DialogActions>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    // disabled={isSubmitting}
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const styles = (theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});

UserModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(
    compose(
      graphql(addSuperAdminUserMutation, {
        name: "addSuperAdminUserMutation",
      })
    )(UserModal)
  )
);
