import React, { useState,useEffect } from "react";
import { signupPlanResourcesBySignupPlanIdRootQuery } from "./signupPlansGQLQuery";
import {
  Typography,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useQuery } from "react-apollo";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";
import makeAnimated from "react-select/animated";

const defaultSorted = [
  {
    id: "resource_name",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;
const ResourceList = (props) => {
  const signupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall=()=>{
    signupPlanResourcesBySignupPlanIdRootQueryRefetch()    
  }

  const {
    data: signupPlanResourcesBySignupPlanIdRootQueryData,
    loading: signupPlanResourcesBySignupPlanIdRootQueryLoading,
    refetch : signupPlanResourcesBySignupPlanIdRootQueryRefetch,
    error: signupPlanResourcesBySignupPlanIdRootQueryError,
    fetchMore: signupPlanResourcesBySignupPlanIdRootQueryFetchMore,
  } = useQuery(signupPlanResourcesBySignupPlanIdRootQuery, {
    variables: {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    // onCompleted: initializeScreen,
    fetchPolicy: "network-only",
  });


  useEffect(()=>{    
    props.bindSignupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall(signupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall)
  },[signupPlanResourcesBySignupPlanIdRootQueryRefetchOnCall])

  

  useEffect(() => { 
   
    if (
      signupPlanResourcesBySignupPlanIdRootQueryData &&
      signupPlanResourcesBySignupPlanIdRootQueryData.signupPlanResourcesBySignupPlanId &&
      signupPlanResourcesBySignupPlanIdRootQueryData.signupPlanResourcesBySignupPlanId
        .signupPlanResources
    ) {
      setSignupPlanResourceList(
        signupPlanResourcesBySignupPlanIdRootQueryData.signupPlanResourcesBySignupPlanId
          .signupPlanResources
      );
      setPages(
        signupPlanResourcesBySignupPlanIdRootQueryData.signupPlanResourcesBySignupPlanId
          .signupPlanResources
          ? Math.ceil(
              signupPlanResourcesBySignupPlanIdRootQueryData
                .signupPlanResourcesBySignupPlanId.rowCount / defaultPageSize
            )
          : 1
      );
    } else {
      setSignupPlanResourceList([]);
    }
  }, [signupPlanResourcesBySignupPlanIdRootQueryData]);


  //  shouldComponentUpdate(nextProp, nextState) {
  //   if (nextProp.signuPlanDataVersion !== props.signuPlanDataVersion) {
  //     signupPlanResourcesBySignupPlanIdRootQueryRefetch();
  //     // this.state.signuPlanDataVersion = props.signuPlanDataVersion;
  //     // this.props.getAllModulesRootQuery.refetch();
  //   }
  //   return true;
  // }

  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const [signupPlanResourceList, setSignupPlanResourceList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);

  const columns= [
    {
      HeaderText: "Resource Name",
      Header: (props) => (
        <div className="draggable-header">
        <span className="reactTable-headerText">
          {"Resource Name"}
          {sortedArray &&
            sortedArray.filter(
              (iName) => iName.id === "resource_name"
            ).length > 0 && (
              <Icon className="sortableArrow">arrow_right_alt</Icon>
            )}
        </span>
      </div>    
      ),
      id: "resource_name",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.resourceName.charAt(0) +
              props.original.resourceName.slice(1).toLowerCase().replace("_"," ")}
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Resource Type",
      Header: (props) => (
        <div className="draggable-header">
        <span className="reactTable-headerText">
          {"Resource Type"}
          {sortedArray &&
            sortedArray.filter(
              (iName) => iName.id === "resource_type"
            ).length > 0 && (
              <Icon className="sortableArrow">arrow_right_alt</Icon>
            )}
        </span>
      </div>    
      ),
      id: "resource_type",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.resourceType.charAt(0) +
              props.original.resourceType
                .slice(1)
                .toLowerCase()
                .replaceAll("_", " ")}
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Reset Cycle",
      Header: (props) => (
        <div className="draggable-header">
        <span className="reactTable-headerText">
          {"Reset Cycle"}
          {sortedArray &&
            sortedArray.filter(
              (iName) => iName.id === "reset_cycle"
            ).length > 0 && (
              <Icon className="sortableArrow">arrow_right_alt</Icon>
            )}
        </span>
      </div>    
      ),
      id: "reset_cycle",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.resetCycle
              ? props.original.resetCycle.charAt(0) +
                props.original.resetCycle.slice(1).toLowerCase()
              : "-"}
          </div>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Included Count",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>
              Included Count
            </Typography>
          </span>
        </div>
      ),
      id: "included_name",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.includedCount}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Ratio",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>
              Ratio
            </Typography>
          </span>
        </div>
      ),
      id: "ratio",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.ratio}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Max Count",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>
              Max Count
            </Typography>
          </span>
        </div>
      ),
      id: "max_count",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.maxCount}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "PPAU",
      Header: (props) => (
        <div
          style={{ display: "flex", cursor: "default" }}
          className="draggable-header"
        >
          <span className="reactTable-headerText">
            <Typography style={{ fontWeight: "bold" }}>PPAU</Typography>
          </span>
        </div>
      ),
      id: "price_per_additional_count",
      Cell: (props) => {
        return (
          <div className="flex items-center capitalize">
            {props.original.pricePerAdditionalCount}
          </div>
        );
      },
      sortable: false,
      multiSort: false,
    },
  ];

  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };

    let historyFilters = {
      signupPlanId: parseInt(props.signupPlanId),
      sortedBy: sortedArray,
      limit: pageSize || 20,
      offset: page || 1,
    };
    queryHistory.set(
      props,
      "getSystemClientsPaginationAndGroupByQuery",
      historyFilters
    );
    signupPlanResourcesBySignupPlanIdRootQueryRefetch(newQueryVariables);
  };

   var height = props.height || window.innerHeight - 165;
  return (
    <div style={{ padding: 8 }} className="reactTableWraper">
      <DraggableTable
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        pages={pages} // Display the total number of pages
        loading={signupPlanResourcesBySignupPlanIdRootQueryLoading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        data={signupPlanResourceList}
        expanderDefaults={{ width: 0 }}
        columns={columns}
        resizable={true}
        noDataText="No Record Found"
        minRows={1}
        defaultSorted={sortedArray}
        pageSizeOptions={[20, 25, 50, 100]}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onPageSizeChange={(sz) => {
          setCurrentPage(0);
          setPageSize(sz);
        }}
        multiSort={true}
        style={{
          maxHeight: height,          
          cursor: "default",
        }}      
      />
    </div>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps)(ResourceList);
