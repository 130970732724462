import React, { useState, useRef, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let ReachTextEditor = function(props) {
  const [lines, setLines] = useState(0);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef(null);
  let focusRef = useRef(null);
  const handleEditorChange = function(e) {
    const prevLines = lines;

    if (prevLines !== e.blocks.length) {
      setLines(e.blocks.length);
    }

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const handleEditorStateChange = function(e) {
    setEditorState(e);
    if (props.onEditorStateChange) {
      props.onEditorStateChange(e);
    }
  };

  useEffect(() => {
    const scrollDiv = editorRef.current.querySelector(".rdw-editor-main") || {};
    let totalScrollHeight = scrollDiv.scrollHeight;
    let totalClientHeight = scrollDiv.clientHeight;
    let totalScrollTop = scrollDiv.scrollTop;
    let scrollThreshold =
      totalScrollHeight - totalClientHeight - totalScrollTop < 20;

    scrollDiv.scrollTop = scrollThreshold
      ? totalScrollHeight - totalClientHeight
      : totalScrollTop;
  }, [lines]);

  useEffect(() => {
    // console.log("======5",props.editorState)
    if (props.editorState) {
      setEditorState(props.editorState);
    }
  }, [props.editorState]);

  useEffect(() => {
    if (props.autoFocus) {
      focusRef.focus();
    }
  }, [props.autoFocus]);

  return (
    <div className="row" ref={editorRef}>
      <Editor
        editorRef={(ref) => (focusRef = ref)}
        editorState={editorState}
        {...props}
        onChange={handleEditorChange}
        onEditorStateChange={handleEditorStateChange}
      />
    </div>
  );
};

export default ReachTextEditor;
