import gql from "graphql-tag";

export const getIndustriesPaginationAndGroupByQuery = gql`
  query getIndustriesPaginationAndGroupByQuery($limit: Int, $offset: Int) {
    industries(limit: $limit, offset: $offset) {
      industries {
        id
        industryName
        industryDisplayName
        rowCount
      }
      rowCount
    }
  }
`;
export const getIndustriesRootQuery = gql`
  query getIndustriesRootQuery {
    industry {
      id
      industryName
      industryDisplayName
    }
  }
`;
export const addIndustriesMutation = gql`
  mutation addIndustry($industries: industryInputType) {
    addIndustry(industries: $industries) {
      id
      industryName
      industryDisplayName
    }
  }
`;
export const editIndustriesMutation = gql`
  mutation editIndustry($industries: industryInputType) {
    editIndustry(industries: $industries) {
      id
      industryName
      industryDisplayName
    }
  }
`;
export const deleteIndustriesMutation = gql`
  mutation deleteIndustry($industryId: Int) {
    deleteIndustry(industryId: $industryId) {
      id
      industryName
      industryDisplayName
    }
  }
`;
