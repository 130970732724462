import {
  GET_ALL_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTION,
  ADD_NEW_SUBSCRIPTION,
  EDIT_SUBSCRIPTION
} from "../../reduxConfig/actionTypes";

let defaultState = [];

export default function(state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTIONS:
      return action.payload;
    case DELETE_SUBSCRIPTION:
      return state.filter(i => i.id !== action.payload);
    case ADD_NEW_SUBSCRIPTION:
      return [...state, action.payload];
    case EDIT_SUBSCRIPTION:
      return state.map(item => {
        if (item.id !== action.payload.id) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload
        };
      });
    default:
      return state;
  }
}
