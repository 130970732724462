import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { signupPlansPaginationAndGroupByRootQuery } from "./signupPlansGQLQuery";
import {
  Typography,
  Popover,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
  FormControl,
  Select as MUISelect,
  Button,
  Grid,
  Input,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import Select from "react-select";
import { useQuery } from "react-apollo";
import { compose } from "recompose";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import Spinner from "../common/Spinner";
import { useSelector, connect } from "react-redux";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";
import { industryQuery } from "../plans/signupPlansGQLQuery";
import makeAnimated from "react-select/animated";
import PlanModal from "./PlanModal";

const defaultPageSize = 20;
const defaultPage = 1;
const defaultSorted = [
  {
    id: "created_datetime",
    desc: true,
  },
];

const Plans = (props) => {
  const {
    data: signupPlansPaginationAndGroupByData,
    loading: signupPlansPaginationAndGroupByLoading,
    refetch: signupPlansPaginationAndGroupByRefetch,
    error: signupPlansPaginationAndGroupByError,
    fetchMore: signupPlansPaginationAndGroupByFetchMore,
  } = useQuery(signupPlansPaginationAndGroupByRootQuery, {
    variables: {
      searchtext: null,
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      signupPlansPaginationAndGroupByData &&
      signupPlansPaginationAndGroupByData.signupPlansPaginationAndGroupBy &&
      signupPlansPaginationAndGroupByData.signupPlansPaginationAndGroupBy
        .signupPlans
    ) {
      setSignupPlansList(
        signupPlansPaginationAndGroupByData.signupPlansPaginationAndGroupBy
          .signupPlans
      );
      setPages(
        signupPlansPaginationAndGroupByData.signupPlansPaginationAndGroupBy
          .signupPlans
          ? Math.ceil(
              signupPlansPaginationAndGroupByData
                .signupPlansPaginationAndGroupBy.rowCount / defaultPageSize
            )
          : 1
      );
    } else {
      setSignupPlansList([]);
    }
  }, [signupPlansPaginationAndGroupByData]);

  const [signupPlansList, setSignupPlansList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  let [searchtext, setSearchtext] = useState("");
  const [titleName, setTitleName] = useState("Add Plan");
  const [openDialog, setOpenDialog] = useState(false);
  const [planDataVersion, setPlanDataVersion] = useState(uuidv4);
  const columns = [
    {
      HeaderText: "Plan Name",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Plan Name"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "plan_name").length >
                0 && <Icon className="sortableArrow">arrow_right_alt</Icon>}
          </span>
        </div>
      ),
      id: "plan_name",
      HeaderText: "Plan Name",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.planName}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      HeaderText: "Industry Name",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Industry Name"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "industry_name")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "industry_name",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.industryDisplayName}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
    {
      Header: " Published",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.isPublished ? "Yes" : "No"}
            </span>
          </>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Price",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">{"Price"}</span>
        </div>
      ),
      id: "price",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {props.original.signupPlanPriceList
                .filter((j) => j.commitment === "NO COMMITMENT")
                .map(
                  (i, idx) =>
                    "$ " +
                    i.price +
                    " " +
                    i.renewal.charAt(0) +
                    i.renewal.slice(1).toLowerCase()
                )
                .join(" / ")}
            </span>
          </>
        );
      },
      sortable: false,
      multiSort: false,
    },
    {
      HeaderText: "Created Datetime",
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Created Datetime"}
            {sortedArray &&
              sortedArray.filter((iName) => iName.id === "created_datetime")
                .length > 0 && (
                <Icon className="sortableArrow">arrow_right_alt</Icon>
              )}
          </span>
        </div>
      ),
      id: "created_datetime",
      Cell: (props) => {
        return (
          <>
            <span className="flex items-center capitalize">
              {moment(props.original.createdDatetime)
                .tz("America/New_York")
                .format("MM-DD-YYYY HH:mm:ss")}
            </span>
          </>
        );
      },
      sortable: true,
      multiSort: true,
    },
  ];

  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      searchtext: searchtext || null,
      sortedBy: sortedArray,
      limit: pageSize,
      offset: page,
    };

    signupPlansPaginationAndGroupByRefetch(newQueryVariables);
  };

  const redirectToPlansDetails = (Id) => {
    const { history } = props;
    history.push(`/admin/plans/PlansDetails/${Id}`);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
    setTitleName("Add plan");
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  var height = window.innerHeight - 155;
  const { classes } = props;
  return (
    <div className="reactTableWraper">
      {signupPlansPaginationAndGroupByLoading && <Spinner />}
      <div className="header-Wraper justifyContentEnd">
        <div className="header-Wraper-right">
          <div className="formInputWraper">
            <Input
              placeholder={"Search"}
              className="flex flex-1 w-full"
              disableUnderline
              // fullWidth
              inputProps={{
                "aria-label": "Search",
                className: "inputStyle",
              }}
              value={searchtext}
              onChange={async (e) => {
                if (e.target.value) {
                  searchtext = e.target.value;
                  setSearchtext(e.target.value);
                } else {
                  await setSearchtext("");
                  searchtext = "";
                  getData(defaultPageSize, defaultPage);
                }
              }}
              onKeyPress={(e) => {
                if (e && e.key === "Enter") {
                  getData(defaultPageSize, defaultPage);
                }
              }}
            />
            <IconButton
              color="inherit"
              onClick={(ev) => {
                ev.stopPropagation();
                getData(defaultPageSize, defaultPage);
              }}
            >
              <Icon>search</Icon>
            </IconButton>
          </div>
        </div>
        <div className="addButtonStyle" style={{ marginLeft: 8 }}>
          <Button
            size="medium"
            variant="contained"
            className="btnSmall panelAddButton p-0"
            style={{
              maxWidth: 94,
              maxHeight: 34,
              backgroundColor: "#387DFF",
            }}
            onClick={handleClickOpen}
          >
            <Typography
              style={{
                fontSize: 12,
                color: "white",
                textTransform: "capitalize",
              }}
            >
              Add Plan
            </Typography>
          </Button>
        </div>
      </div>
      <DraggableTable
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        pages={pages} // Display the total number of pages
        loading={signupPlansPaginationAndGroupByLoading} // Display the loading overlay when we need it
        onFetchData={fetchData} // Request new data when things change
        data={signupPlansList}
        expanderDefaults={{ width: 0 }}
        columns={columns}
        resizable={true}
        noDataText={
          !signupPlansPaginationAndGroupByLoading &&
          signupPlansList.length === 0 &&
          searchtext ? (
            <Typography>{"No results for " + searchtext.trim()}</Typography>
          ) : (
            "No record found"
          )
        }
        minRows={1}
        defaultSorted={sortedArray}
        pageSizeOptions={[20, 25, 50, 100]}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        onPageSizeChange={(sz) => {
          setCurrentPage(0);
          setPageSize(sz);
        }}
        multiSort={true}
        collapseOnDataChange={false}
        style={{
          maxHeight: height - 65,
          cursor: "pointer",
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (ev) => {
              ev.stopPropagation();
              redirectToPlansDetails(rowInfo.original.id);
            },
          };
        }}
      />

      <Dialog
        fullWidth={true}
        scroll={"paper"}
        maxWidth="xs"
        style={{ overflow: "hidden" }}
        disableBackdropClick
        open={openDialog}
        onClose={handleClose}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          style={{
            padding: 5,
            backgroundColor: "#3F51B5",
            color: "#fff",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ marginLeft: 8, fontSize: 18 }}>
              {titleName}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "white" }} color="fff" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContentRoot,
          }}
        >
          <PlanModal
            onSuccess={(PlanId) => {
              redirectToPlansDetails(PlanId);
              signupPlansPaginationAndGroupByRefetch();
              setPlanDataVersion(uuidv4());
            }}
            PlanDataVersion={planDataVersion}
            handleClose={handleClose}
            // signupPlanId={props.match.params.Id}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const styles = () => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});
const mapStateToProps = ({}) => {
  return {};
};

export default withStyles(styles)(connect(mapStateToProps)(Plans));
