/* eslint-disable react/prop-types */
import React, { Component } from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {AppBar,Toolbar,IconButton,Typography,MenuItem,Menu,Button,Avatar,Icon,ListItemIcon,ListItemText} from "@material-ui/core";
// @material-ui/icons
// core components
import MenuIcon from "@material-ui/icons/Menu";
import styles from "../assets/jss/defaultLayoutStyle";
import { connect } from "react-redux";
import { doLogout } from "../pages/login/loginActions";
import { withToastManager } from "react-toast-notifications";
// import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nchorEl: null,
      mobileMoreAnchorEl: null,
      anchorElmn: null,
    };
  }

  makeBrand() {
    var name;
    this.props.routes.map(prop => {
      if (prop.layout + prop.path === this.props.location.pathname) {
        name = this.props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };
  appDoLogout = async () => {
    let showNotification = await this.props.doLogout();
    localStorage.clear();
    console.log(showNotification);
    if (showNotification) {
      this.props.toastManager.add("Logged Out Successfully", {
        appearance: "success",
        autoDismiss: true,
        pauseOnHover: false
      });
    } else {
      this.props.toastManager.add("Something went wrong", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false
      });
    }
  };

  userMenuClick = async (event) => {
    this.setState({ anchorElmn: event.currentTarget });
  };
  userMenuClose = () => {
    this.setState({ anchorElmn: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div className="headerWraper">
      <AppBar
        position="absolute"
        className={classNames(
          classes.appBar,
          this.props.open && classes.appBarShift
        )}
      >
        <Toolbar disableGutters={!this.props.open} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={this.props.handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              this.props.open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {this.makeBrand()}
          </Typography>
         
          
         {this.props.login && this.props.login.user && <Button
            className="h-64 pr-8 userDetailsStyle"
            onClick={this.userMenuClick}
          >
            <div className="hidden md:flex flex-col ml-12 items-start">
              <Typography component="span" className="normal-case font-600 flex">
                {this.props.login.user.email}
              </Typography>
            </div>
            <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
              keyboard_arrow_down
            </Icon>
          </Button>}

          <Menu
            anchorEl={this.state.anchorElmn}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(this.state.anchorElmn)}
            style={{ top: 40 }}
            onClose={this.userMenuClose}
            className="menuStyleCss"
          >  
            <MenuItem onClick={this.appDoLogout}>
                <ListItemIcon className="min-w-40">
                  <Icon>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText
                  className="pl-0"
                  primary={'Log out'}
                />
            </MenuItem>
          </Menu>

        </Toolbar>
      </AppBar>
      </div>
    );
  }
}

const mapStateToProps = ({ login }) => {
  return { login };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { doLogout }
  )(withToastManager(Header))
);
