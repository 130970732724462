import Clients from "./pages/clients/Clients";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import Multilingual from "./pages/multilingual/Multilingual";
import Industry from "./pages/industry/Industry";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import PersonOutline from "@material-ui/icons/PersonOutline";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AttachMoney from "@material-ui/icons/AttachMoney";
import LanguageIcon from "@material-ui/icons/Language";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import BusinessIcon from "@material-ui/icons/Business";
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import ClientsDetails from "./pages/clients/ClientsDetails";
import SubscriptionsDetails from "./pages/subscriptions/SubscriptionsDetails";
import SbizzlMaintenance from "./pages/sbizzlMaintenance/SbizzlMaintenance";
import Plans from "./pages/plans/Plans";
import PlansDetails from "./pages/plans/PlansDetails";
import CustomerInvoice from "./pages/customerInvoice/CustomerInvoice";
import SubscriptionJob from "./pages/subscriptionJobs/SubscriptionJob";
import Users from "./pages/users/Users";
import CachedIcon from '@material-ui/icons/Cached';
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ReleaseNotes from "./pages/releaseNotes/ReleaseNotes";

const dashboardRoutes = [
  {
    path: "/clients",
    name: "Client List",
    rtlName: "قائمة الجدول",
    icon: PeopleOutline,
    component: Clients,
    layout: "/admin",
    exact: true,
    sidebarShow: true
  },
  {
    path: "/clients/ClientsDetails/:Id",
    name: "Client Details",
    rtlName: "قائمة الجدول",
    icon: PeopleOutline,
    component: ClientsDetails,
    layout: "/admin",
    exact: true,
    sidebarShow: false
  },
  {
    path: "/customerInvoice",
    name: "Customer Invoice",
    rtlName: "قائمة الجدول",
    icon: FileCopyIcon,
    component: CustomerInvoice,
    layout: "/admin",
    exact: true,
    sidebarShow: true
  },
  // {
  //   path: "/subscriptions",
  //   name: "Subscriptions List",
  //   rtlName: "قائمة الجدول",
  //   icon: AttachMoney,
  //   component: Subscriptions,
  //   layout: "/admin",
  //   exact: true,
  //   sidebarShow: true
  // },
  // {
  //   path: "/subscriptions/SubscriptionsDetails/:Id",
  //   name: "Subscriptions Details",
  //   rtlName: "قائمة الجدول",
  //   icon: AttachMoney,
  //   component: SubscriptionsDetails,
  //   layout: "/admin",
  //   exact: true,
  //   sidebarShow: false
  // },
  {
    path: "/multilingual",
    name: "Multilingual List",
    rtlName: "متعدد اللغات",
    icon: LanguageIcon,
    component: Multilingual,
    layout: "/admin",
    sidebarShow: true
  },
  {
    path: "/industry",
    name: "Industry List",
    // rtlName: "متعدد اللغات",
    icon: BusinessIcon,
    component: Industry,
    layout: "/admin",
    sidebarShow: true
  },
  {
    path: "/sbizzlMaintenance",
    name: "Maintenance List",
    // rtlName: "متعدد اللغات",
    icon: SystemUpdateAltIcon,
    component: SbizzlMaintenance,
    layout: "/admin",
    sidebarShow: true
  },
  {
    path: "/releaseNotes",
    name: "Release Notes",
    // rtlName: "متعدد اللغات",
    icon: FormatListBulletedIcon,
    component: ReleaseNotes,
    layout: "/admin",
    sidebarShow: true
  },
  {
    path: "/plans",
    name: "Plans",
    //  rtlName: "متعدد اللغات",
    icon: AttachMoneyIcon,
    component: Plans,
    layout: "/admin",
    exact: true,
    sidebarShow: true
  },
  {
    path: "/plans/PlansDetails/:Id",
    name: "Plan Details",
    // rtlName: "قائمة الجدول",
    icon: PeopleOutline,
    component: PlansDetails,
    layout: "/admin",
    exact: true,
    sidebarShow: false
  },
  {
    path: "/subscriptionJob",
    name: "Subscription Job",
    // rtlName: "قائمة الجدول",
    icon: NavigateNextIcon,
    component: SubscriptionJob,
    layout: "/admin",
    exact: true,
    sidebarShow: true
  },
  {
    path: "/users",
    name: "User List",
    rtlName: "قائمة الجدول",
    icon: PersonOutline,
    component: Users,
    layout: "/admin",
    exact: true,
    sidebarShow: true
  },
  {
    path: "/resetPassword",
    name: "Reset Password",
    rtlName: "پاس ورڈ ری سیٹ",
    icon: CachedIcon,
    component: ResetPassword,
    layout: "/admin",
    exact: true,
    sidebarShow: true
  },
];

export default dashboardRoutes;
