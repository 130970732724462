import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import {
  Typography,
  Input,
  IconButton,
  Button,
  CircularProgress,
  Chip,
  Popover,
  Grid,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import "react-table/react-table.css";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Spinner from "../common/Spinner";
import {
  getScInvoiceBillingListByFilterPaginationAndGroupByQuery,
  downloadScInvoicePdf,
} from "./customerInvoiceGqlQuery";

const defaultPageSize = 20;
const defaultPage = 1;
const defaultSorted = [
  {
    id: "createdOn",
    desc: true,
  },
];

const defaultFromDate = null;
const defaultToDate = null;

class CustomerInvoice extends Component {
  constructor(props) {
    super(props);
    this.refReactTable = {};
    const { classes } = this.props;
    let historyVars =
      queryHistory.get(
        props,
        "getScInvoiceBillingListByFilterPaginationAndGroupByQuery"
      ) || {};

    let defaultSetting = null;

    let sortedArray =
      defaultSetting && defaultSetting.sortedBy
        ? defaultSetting.sortedBy
        : null;

    this.state = {
      queryDidLoad: false,
      defaultPage: defaultPage,
      pages: defaultPage,
      downloadPdfLoader: false,
      currentPage: historyVars.offset ? historyVars.offset : 0,
      pageSize: historyVars.limit ? historyVars.limit : defaultPageSize,
      sortedArray:
        sortedArray && sortedArray.length > 0 ? sortedArray : defaultSorted,
      isMenuOpen: false,
      systemClientInvoiceDetail: [],
      mainTableHeader: [],
      searchtext: historyVars.searchtext,
      fromDate: defaultFromDate,
      toDate: defaultToDate,
      anchorElMenu: null,
      columns: [
        {
          key: "company",
          column: {
            HeaderText: "Company",
            style: { whiteSpace: "unset" },
            Header: (props) => (
              <div className="draggable-header">
                <span className="reactTable-headerText">
                  {"Company"}
                  {this.state.sortedArray &&
                    this.state.sortedArray.filter(
                      (iName) => iName.id === "company"
                    ).length > 0 && (
                      <Icon className="sortableArrow">arrow_right_alt</Icon>
                    )}
                </span>
              </div>
            ),
            headerClassName: "column-header",
            id: "company",
            Cell: (props) => {
              return (
                <span className="tableMainName">
                  {props.original.clientName ? props.original.clientName : ""}
                </span>
              );
            },
            sortable: true,
            multiSort: true,
          },
        },
        {
          key: "invoiceCode",
          column: {
            HeaderText: "Invoice Code",
            style: { whiteSpace: "unset" },
            Header: (props) => (
              <div className="draggable-header">
                <span className="reactTable-headerText">
                  {"Invoice Code"}
                  {this.state.sortedArray &&
                    this.state.sortedArray.filter(
                      (iName) => iName.id === "invoiceCode"
                    ).length > 0 && (
                      <Icon className="sortableArrow">arrow_right_alt</Icon>
                    )}
                </span>
              </div>
            ),
            headerClassName: "column-header",
            id: "invoiceCode",
            Cell: (props) => {
              return (
                <span>
                  {props.original.invoiceCode ? props.original.invoiceCode : ""}
                </span>
              );
            },
            sortable: true,
            multiSort: true,
          },
        },
        {
          key: "createdOn",
          column: {
            Header: "Invioce Date",
            style: { whiteSpace: "unset" },
            // minWidth: 75,
            Header: (props) => (
              <div className="draggable-header">
                <span className="reactTable-headerText">
                  {"Invoice Date"}
                  {this.state.sortedArray &&
                    this.state.sortedArray.filter(
                      (iName) => iName.id === "createdOn"
                    ).length > 0 && (
                      <Icon className="sortableArrow">arrow_right_alt</Icon>
                    )}
                </span>
              </div>
            ),
            headerClassName: "column-header",
            id: "createdOn",
            Cell: (props) => {
              return (
                <div className="flex flex-row flex-no-wrap">
                  <span>
                    {props.original.invoiceDate
                      ? props.original.invoiceDate
                      : ""}
                  </span>
                </div>
              );
            },
            show: true,
            mandatory: true,
            sortable: true,
            multiSort: true,
          },
        },
        {
          key: "paidOn",
          column: {
            Header: "Paid On",
            style: { whiteSpace: "unset" },
            Header: (props) => (
              <div className="draggable-header">
                <span className="reactTable-headerText">
                  {"Paid on"}
                  {this.state.sortedArray &&
                    this.state.sortedArray.filter(
                      (iName) => iName.id === "paidOn"
                    ).length > 0 && (
                      <Icon className="sortableArrow">arrow_right_alt</Icon>
                    )}
                </span>
              </div>
            ),
            headerClassName: "column-header",
            id: "paidOn",
            Cell: (props) => {
              return (
                <div className="flex flex-row flex-no-wrap">
                  <span>
                    {props.original.paymentDate
                      ? props.original.paymentDate
                      : ""}
                  </span>
                </div>
              );
            },
            show: true,
            mandatory: true,
            sortable: true,
            multiSort: true,
          },
        },
        {
          key: "dueOn",
          column: {
            Header: "Due On",
            style: { whiteSpace: "unset", cursor: "default" },
            Header: (props) => (
              <div className="draggable-header" style={{ cursor: "default" }}>
                <span className="reactTable-headerText">
                  {"Due on"}
                  {this.state.sortedArray &&
                    this.state.sortedArray.filter(
                      (iName) => iName.id === "dueOn"
                    ).length > 0 && (
                      <Icon className="sortableArrow">arrow_right_alt</Icon>
                    )}
                </span>
              </div>
            ),
            headerClassName: "column-header",
            id: "dueOn",
            Cell: (props) => {
              return (
                <div className="flex flex-row flex-no-wrap">
                  <span>
                    {props.original.dueDate ? props.original.dueDate : ""}
                  </span>
                </div>
              );
            },
            show: true,
            mandatory: true,
            sortable: true,
            multiSort: true,
          },
        },
        {
          key: "status",
          column: {
            Header: "Status",
            style: { whiteSpace: "unset", cursor: "default" },
            Header: (props) => (
              <div className="draggable-header" style={{ cursor: "default" }}>
                <span className="reactTable-headerText">{"Status"}</span>
              </div>
            ),
            headerClassName: "column-header",
            id: "status",
            Cell: (props) => {
              return (
                <div className="flex flex-row flex-no-wrap">
                  <span
                    style={{
                      color:
                        props.original.status === "Paid"
                          ? "#2CA01C"
                          : "#F87757",
                    }}
                  >
                    {props.original.status}
                  </span>
                </div>
              );
            },
            show: true,
            mandatory: true,
            sortable: false,
            multiSort: false,
          },
        },
        {
          key: "totalCost",
          column: {
            Header: "Total cost",
            style: { whiteSpace: "unset", cursor: "default" },
            minWidth: 90,
            Header: (props) => (
              <div className="draggable-header" style={{ cursor: "default" }}>
                <span className="reactTable-headerText">{"Total cost"}</span>
              </div>
            ),
            headerClassName: "column-header",
            id: "totalCost",
            Cell: (props) => {
              return (
                <div className="flex flex-row flex-no-wrap">
                  <span>
                    {props.original.totalAmount
                      ? "$ " + props.original.totalAmount
                      : "$ 0"}
                  </span>
                </div>
              );
            },
            show: true,
            mandatory: true,
            sortable: false,
            multiSort: false,
          },
        },
        {
          key: "action",
          className: "font-red",
          width: 200,
          column: {
            HeaderText: "Action",
            Header: (props) => (
              <div className="draggable-header" style={{ cursor: "default" }}>
                <span className="reactTable-headerText">{"Action"}</span>
              </div>
            ),
            headerClassName: "column-header",
            id: "action",
            Cell: (props) => {
              return !this.state["loader" + props.original.id] ? (
                <a href={true}>
                  <Typography
                    style={{
                      color: "#02A8EE",
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      this.handleGenerateScInvoicePdf(
                        props.original.id,
                        props.original.systemClientId
                      );
                    }}
                  >
                    {props.original.id ? "Download" : ""}
                  </Typography>
                </a>
              ) : this.state["loader" + props.original.id] ? (
                <div
                  style={{
                    display: "flex",
                    marginLeft: 25,
                    marginRight: 15,
                  }}
                >
                  <CircularProgress size={15} />
                </div>
              ) : null;
            },
            show: true,
            mandatory: false,
            sortable: false,
          },
        },
      ],
    };
  }

  handleGenerateScInvoicePdf = (id, systemClientId) => {
    this.setState({ ["loader" + id]: true });
    this.props.downloadScInvoicePdf
      .refetch({
        id: id,
        systemClientId: systemClientId,
      })
      .then((data) => {
        window.open(data.data.downloadScInvoicePdf);
        this.setState({ ["loader" + id]: false });
      })
      .catch((err) => {
        console.log("error during generate pdf", err);
        this.setState({ ["loader" + id]: false });
      });
  };

  generateColumns = () => {
    let tempColumns = [...this.state.columns.map((i) => ({ ...i }))];
    let newColumns = [];
    if (!this.state.selectedGroup) {
      return this.state.columns.map((i) => i.column);
    }
    this.state.selectedGroup.map((currGroupByElement, GroupByindex) => {
      tempColumns = tempColumns.map((i, idx) => {
        if (currGroupByElement.key === i.key) {
          newColumns.push(i);
          i.remove = true;
          return i;
        }
        return i;
      });

      return currGroupByElement;
    });
    return [...newColumns, ...tempColumns.filter((i) => !i.remove)].map(
      (i) => i.column
    );
  };

  initializeScreen = () => {
    if (
      !this.props.getScInvoiceBillingListByFilterPaginationAndGroupByQuery
        .loading &&
      !this.state.queryDidLoad
    ) {
      this.setState(
        {
          pages: this.props
            .getScInvoiceBillingListByFilterPaginationAndGroupByQuery
            .scInvoiceBillingListByFilterPaginationAndGroupByQuery
            ? Math.ceil(
                this.props
                  .getScInvoiceBillingListByFilterPaginationAndGroupByQuery
                  .scInvoiceBillingListByFilterPaginationAndGroupByQuery
                  .rowCount / defaultPageSize
              )
            : 1,
          systemClientInvoiceDetail: this.props
            .getScInvoiceBillingListByFilterPaginationAndGroupByQuery
            .scInvoiceBillingListByFilterPaginationAndGroupByQuery
            ? this.props
                .getScInvoiceBillingListByFilterPaginationAndGroupByQuery
                .scInvoiceBillingListByFilterPaginationAndGroupByQuery
                .scInvoiceBilling
            : [],
          mainTableHeader: this.generateColumns(),
        },
        () => {
          this.setState({
            queryDidLoad: true,
          });
        }
      );
    }
  };

  getData = async (pageSize, page) => {
    if (
      this.props.getScInvoiceBillingListByFilterPaginationAndGroupByQuery
        .loading
    ) {
      return;
    }

    let queryVariables = {
      sortedBy: this.state.sortedArray || [],
      limit: pageSize,
      offset: page,
      searchtext: this.state.searchtext || null,
      filterData: {
        fromDate: this.state.fromDate
          ? moment(this.state.fromDate).format("YYYY-MM-DD")
          : null,
        toDate: this.state.toDate
          ? moment(this.state.toDate).format("YYYY-MM-DD")
          : null,
      },
    };

    // queryHistory.set(
    //   this.props,
    //   "getScInvoiceBillingListByFilterPaginationAndGroupByQuery",
    //   queryVariables
    // );
    this.props.getScInvoiceBillingListByFilterPaginationAndGroupByQuery
      .refetch(queryVariables)
      .then((i) => {
        this.setState({
          systemClientInvoiceDetail: !this.props
            .getScInvoiceBillingListByFilterPaginationAndGroupByQuery.loading
            ? this.props
                .getScInvoiceBillingListByFilterPaginationAndGroupByQuery
                .scInvoiceBillingListByFilterPaginationAndGroupByQuery
                .scInvoiceBilling
            : [],
          pages: this.props
            .getScInvoiceBillingListByFilterPaginationAndGroupByQuery
            .scInvoiceBillingListByFilterPaginationAndGroupByQuery
            ? Math.ceil(
                this.props
                  .getScInvoiceBillingListByFilterPaginationAndGroupByQuery
                  .scInvoiceBillingListByFilterPaginationAndGroupByQuery
                  .rowCount / pageSize
              )
            : 1,
          pageSize: pageSize,
          loading: false,
        });
      });
  };

  fetchData = async (state, instance) => {
    await this.setState({ sortedArray: state.sorted });
    this.refReactTable = instance;
    this.getData(state.pageSize, state.page + 1);
  };

  clearData = () => {
    // queryHistory.clear(this.props);
    // this.refReactTable.setStateWithData({
    //   expanded: {},
    //   sorted: defaultSorted,
    // });
    this.setState(
      {
        fromDate: null,
        toDate: null,
        sorted: defaultSorted,
      },
      () => {
        this.getData(defaultPageSize, defaultPage, "clearData");
      }
    );
  };

  dataGenerator = (data) => {
    return {
      id: data.id || 0,
      systemClientId: data.systemClientId || 0,
      clientName: data.clientName || "",
      invoiceCode: data.invoiceCode || "",
      invoiceDate: data.invoiceDate || "",
      paymentDate: data.paymentDate || "",
      dueDate: data.dueDate || "",
      status: data.status === "Paid" ? "Paid" : "Due",
      totalAmount: data.totalAmount - data.depositAmount || "",
    };
  };

  handleAnchorElMenuOpen = (event) => {
    this.setState({
      anchorElMenu: event.currentTarget,
    });
  };
  handleAnchorElMenuClose = () => {
    this.setState({
      anchorElMenu: null,
    });
  };

  handleChipClear = (type) => {
    if (type === "todate") {
      this.setState(
        {
          toDate: null,
        },
        () => {
          this.getData(defaultPageSize, defaultPage, "clearData");
        }
      );
    } else if (type === "fromDate") {
      this.setState(
        {
          fromDate: null,
        },
        () => {
          this.getData(defaultPageSize, defaultPage, "clearData");
        }
      );
    }
  };
  render() {
    var height = window.innerHeight - 155;
    const { classes } = this.props;
    this.initializeScreen();
    let fromDateString = "From Date : ";
    let toDateString = "To Date : ";
    return (
      <div className="reactTableWraper">
        {this.props.getScInvoiceBillingListByFilterPaginationAndGroupByQuery
          .loading && <Spinner />}
        <div className="header-Wraper justifyContentEnd">
          <div className="header-Wraper-right">
            {this.state.filterState && this.state.fromDate && (
              <Chip
                style={{
                  marginRight: 10,
                  background: "#EBF2FF",
                  borderRadius: 5,
                  maxHeight: 26,
                  borderColor: "white",
                }}
                label={
                  fromDateString +
                  moment(this.state.fromDate).format("YYYY/MM/DD")
                }
                onDelete={() => this.handleChipClear("fromDate")}
                variant="outlined"
              />
            )}
            {this.state.filterState && this.state.toDate && (
              <Chip
                style={{
                  marginRight: 10,
                  background: "#EBF2FF",
                  borderRadius: 5,
                  maxHeight: 26,
                  borderColor: "white",
                }}
                label={
                  toDateString + moment(this.state.toDate).format("YYYY/MM/DD")
                }
                onDelete={() => this.handleChipClear("todate")}
                variant="outlined"
              />
            )}
            <div className="header-Wraper-filterDiv">
              <Button
                aria-describedby={"popover"}
                variant="contained"
                color="primary"
                onClick={this.handleAnchorElMenuOpen}
              >
                <Icon>filter_alt</Icon>
              </Button>
              <Popover
                id="popover"
                open={Boolean(this.state.anchorElMenu)}
                anchorEl={this.state.anchorElMenu}
                onClose={this.handleAnchorElMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={{
                  paper: "popoverOverflow",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // classes={{
                //   paper: "popover-Wraper",
                // }}
              >
                <div className="popoverContentwraper">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 12,
                    }}
                  >
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleAnchorElMenuClose();
                      }}
                      style={{ color: "#0077C5", cursor: "pointer" }}
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="popoverFormDatePickerWraper">
                    <Grid container spacing={2}>
                      <Grid
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        item
                        xs={12}
                      >
                        <Typography>Invoice Date</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          id="from_date"
                          selected={this.state.fromDate}
                          popperPlacement="bottom-start"
                          onChange={(date) => {
                            this.setState({
                              fromDate: date,
                            });
                          }}
                          placeholderText={"From Date"}
                          dateFormat="yyyy/MM/dd"
                          peekNextMonth={true}
                          showMonthDropdown={true}
                          showYearDropdown={true}
                          dropdownMode="select"
                          onFocus={(e) => {
                            if (e) {
                              e.target.select();
                              this.setState({ vDateOutlineLable: true });
                            }
                          }}
                          onClickOutside={() => {
                            this.setState({ vDateOutlineLable: false });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          id="to_date"
                          selected={this.state.toDate}
                          popperPlacement="bottom-start"
                          onChange={(date) => {
                            this.setState({
                              toDate: date,
                            });
                          }}
                          placeholderText={"To Date"}
                          dateFormat="yyyy/MM/dd"
                          peekNextMonth={true}
                          showMonthDropdown={true}
                          showYearDropdown={true}
                          dropdownMode="select"
                          onFocus={(e) => {
                            if (e) {
                              e.target.select();
                              this.setState({ vDateOutlineLable: true });
                            }
                          }}
                          onClickOutside={() => {
                            this.setState({ vDateOutlineLable: false });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="popoverWraperFooter">
                    <Button
                      variant="outlined"
                      color="primary"
                      className="cancelBtn"
                      onClick={() => {
                        this.setState({
                          filterState: false,
                        });
                        this.clearData();
                        this.handleAnchorElMenuClose();
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="filterBtn"
                      onClick={() => {
                        this.setState({
                          filterState: true,
                        });
                        this.getData(defaultPageSize, defaultPage);
                        this.handleAnchorElMenuClose();
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>

            <div className="formInputWraper">
              <Input
                placeholder={"Search"}
                className="flex flex-1 w-full"
                disableUnderline
                // fullWidth
                inputProps={{
                  "aria-label": "Search",
                  className: "inputStyle",
                }}
                value={this.state.searchtext}
                onChange={async (e) => {
                  if (e.target.value) {
                    this.setState({ searchtext: e.target.value });
                  } else {
                    await this.setState({ searchtext: "" });
                    this.getData(defaultPageSize, defaultPage);
                  }
                }}
                onKeyPress={(e) => {
                  if (e && e.key === "Enter") {
                    this.getData(defaultPageSize, defaultPage);
                  }
                }}
              />
              <IconButton
                color="inherit"
                onClick={(ev) => {
                  ev.stopPropagation();
                  this.getData(defaultPageSize, defaultPage);
                }}
              >
                <Icon>search</Icon>
              </IconButton>
            </div>
          </div>
        </div>
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={this.state.pages} // Display the total number of pages
          loading={
            this.props.getScInvoiceBillingListByFilterPaginationAndGroupByQuery
              .loading
          } // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          data={this.state.systemClientInvoiceDetail.map(this.dataGenerator)}
          expanderDefaults={{ width: 0 }}
          // columns={this.state.columns}
          columns={this.state.mainTableHeader}
          resizable={true}
          noDataText={
            !this.props.getScInvoiceBillingListByFilterPaginationAndGroupByQuery
              .loading &&
            this.state.searchtext &&
            this.state.systemClientInvoiceDetail.length === 0 ? (
              <Typography>
                {"No results for " + this.state.searchtext.trim()}
              </Typography>
            ) : (
              <Typography>{"No Record Found"}</Typography>
            )
          }
          minRows={1}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={this.state.pageSize}
          pageSize={this.state.pageSize}
          page={this.state.currentPage}
          onPageChange={(page) => {
            this.setState({ currentPage: page });
          }}
          onPageSizeChange={(sz) => {
            this.setState({
              currentPage: 0,
              pageSize: sz,
            });
          }}
          multiSort={true}
          defaultSorted={this.state.sortedArray}
          collapseOnDataChange={false}
          style={{
            maxHeight: height - 52,
            cursor: "default",
          }}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  formGroupTitle: {
    position: "absolute",
    top: -10,
    left: 8,
    fontWeight: 600,
    padding: "0 4px",
    backgroundColor: theme.palette.background.paper,
  },
  formGroup: {
    position: "relative",
    border: "1px solid " + theme.palette.divider,
    borderRadius: 2,
    padding: "20px 20px 10px",
  },
  sortableArrow: {
    transform: "rotate(90deg)",
    fontSize: "1.8rem",
    transition: "0.3s all",
  },
  reactTableHeaderText: {
    display: "flex",
    alignItems: " center",
    width: "100%",
    textTransform: "capitalize",
  },
});

const mapStateToProps = () => {
  return {};
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {}
  )(
    compose(
      graphql(getScInvoiceBillingListByFilterPaginationAndGroupByQuery, {
        name: "getScInvoiceBillingListByFilterPaginationAndGroupByQuery",
        options: (props) => {
          let historyVar = queryHistory.get(
            props,
            "getScInvoiceBillingListByFilterPaginationAndGroupByQuery"
          );
          return {
            variables: {
              sortedBy: defaultSorted,
              limit: defaultPageSize,
              offset: defaultPage,
              searchtext: null,
              filterData: {
                fromDate: null,
                toDate: null,
              },
            },
            fetchPolicy: "network-only",
          };
        },
      }),
      graphql(downloadScInvoicePdf, {
        name: "downloadScInvoicePdf",
      })
    )(CustomerInvoice)
  )
);
