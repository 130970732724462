import React, { useState, useEffect } from "react";
import {
  getIndustriesPaginationAndGroupByQuery,
  addIndustriesMutation,
  editIndustriesMutation,
  deleteIndustriesMutation,
} from "./industryGQLQuery";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useQuery, useMutation } from "react-apollo";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import DraggableTable from "../common/DraggableTable";
import makeAnimated from "react-select/animated";
import ActionOptionsButton from "../common/ActionOptionsButton";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as Yup from "yup";

const defaultSorted = [
  {
    id: "module_name",
    desc: false,
  },
];
const defaultPageSize = 20;
const defaultPage = 1;
const Industry = (props) => {
  const {
    data: getIndustriesPaginationAndGroupByQueryData,
    loading: getIndustriesPaginationAndGroupByQueryLoading,
    refetch,
    error: getIndustriesPaginationAndGroupByQueryError,
    fetchMore: getIndustriesPaginationAndGroupByQueryFetchMore,
  } = useQuery(getIndustriesPaginationAndGroupByQuery, {
    variables: {
      systemClientId: parseInt(props.systemClientId),
      sortedBy: defaultSorted,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    // onCompleted: (data) => {
    //   console.log("data", data);
    //   setIndustryList(
    //     data.industries.industries
    //       .length > 0
    //       ? cloneDeep(
    //           data.industries.industries
    //         )
    //       : []
    //   );
    //   setPages(
    //     data.industries.industries
    //       ? Math.ceil(
    //           data.industries.rowCount /
    //             defaultPageSize
    //         )
    //       : 1
    //   );
    // },
    // fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      getIndustriesPaginationAndGroupByQueryData &&
      getIndustriesPaginationAndGroupByQueryData.industries &&
      getIndustriesPaginationAndGroupByQueryData.industries.industries
    ) {
      setIndustryList(
        getIndustriesPaginationAndGroupByQueryData.industries.industries
      );
      setPages(
        getIndustriesPaginationAndGroupByQueryData.industries.industries
          ? Math.ceil(
              getIndustriesPaginationAndGroupByQueryData.industries.rowCount /
                defaultPageSize
            )
          : 1
      );
    } else {
      setIndustryList([]);
    }
  }, [getIndustriesPaginationAndGroupByQueryData]);

  const [addIndustries] = useMutation(addIndustriesMutation);
  const [editIndustries] = useMutation(editIndustriesMutation);

  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const [industryList, setIndustryList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [titleName, setTitleName] = useState("Add Industry");
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [initFormData, setinitFormData] = useState({
    id: 0,
    industryName: "",
    industryDisplayName: "",
  });
  const columns = [
    {
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">{"Industry Name"}</span>
        </div>
      ),
      sortable: false,
      multiSort: false,
      Cell: (props) => {
        return (
          <>
            <span>{props.original.industryName}</span>
          </>
        );
      },
    },
    {
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">
            {"Industry Display Name"}
          </span>
        </div>
      ),
      sortable: false,
      multiSort: false,
      Cell: (props) => {
        return (
          <>
            <span>{props.original.industryDisplayName}</span>
          </>
        );
      },
    },
    {
      Header: (props) => (
        <div className="draggable-header">
          <span className="reactTable-headerText">{"Number Of Client"}</span>
        </div>
      ),
      sortable: false,
      multiSort: false,
      Cell: (props) => {
        return (
          <div style={{ textAlign: "flex-start" }}>
            <a
              onClick={(e) => {
                e.stopPropagation();
                handleNavigationToClientListScreen(props.original.id);
              }}
              style={{ color: "#0077C5", cursor: "pointer", marginLeft: 5 }}
            >
              {props.original.rowCount}
            </a>
            {/* </Button> */}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      Cell: (props) => {
        let options = [];
        options.push({
          label: "Edit",
          callBack: () => {
            editIndustry(props.original);
          },
        });
        return <ActionOptionsButton options={options} />;
      },
    },
  ];

  const editIndustry = (data) => {    
    setinitFormData({
      id: data.id,
      industryName: data.industryName,
      industryDisplayName: data.industryDisplayName,
    });
    formik.setValues({
      ...data,
    });
    setOpen(true);
    setTitleName("Edit Industry");
  };

  const handleNavigationToClientListScreen = (id) => {
    const { history } = props;
    history.push({
      pathname: `/admin/clients`,
      state: {
        industryId: id,
      },
    });
  };

  const getData = async (pageSize, page) => {
    let newQueryVariables = {
      systemClientId: parseInt(props.systemClientId),
      sortedBy: sortedArray,
      limit: pageSize || 10,
      offset: page || 1,
    };
    refetch(newQueryVariables);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setTitleName("Add Industry");
    setinitFormData({
      id: 0,
      industryName: "",
      industryDisplayName: "",
    });
    formik.setValues({
      id: 0,
      industryName: "",
      industryDisplayName: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    let Data = {
      id: values.id,
      industryName: values.industryName,
      industryDisplayName: values.industryDisplayName,
    };
    if (values.id > 0) {
      editIndustries({
        variables: {
          industries: Data,
        },
      })
        .then((item) => {
          if (item && item.data && item.data.editIndustry) {
            getData();
            handleClose();
          }
          console.log(item);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      addIndustries({
        variables: {
          industries: Data,
        },
      })
        .then((item) => {
          if (
            item &&
            item.data &&
            item.data.addIndustry &&
            item.data.addIndustry.id
          ) {
            getData();
            handleClose();
          }
          // console.log(item);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  var height = props.height || window.innerHeight - 165;

  const formik = useFormik({
    initialValues: initFormData,
    validateOnBlur: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      industryName: Yup.string().required("Please enter industry name"),
      industryDisplayName: Yup.string().required(
        "Please enter industry displaye name"
      ),
    }),
  });

  return (
    <>
      <div className="reactTableWraper">
        <div className="header-Wraper justifyContentEnd">
          <div className="addButtonStyle" style={{ marginLeft: 8 }}>
            <Button
              size="medium"
              variant="contained"
              className="btnSmall panelAddButton p-0"
              style={{
                maxWidth: 121,
                maxHeight: 34,
                backgroundColor: "#387DFF",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                Add Industry
              </Typography>
            </Button>
          </div>
        </div>
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={pages} // Display the total number of pages
          loading={getIndustriesPaginationAndGroupByQueryLoading} // Display the loading overlay when we need it
          onFetchData={fetchData} // Request new data when things change
          data={industryList}
          expanderDefaults={{ width: 0 }}
          columns={columns}
          resizable={true}
          noDataText="No Record Found"
          minRows={1}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          page={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          onPageSizeChange={(sz) => {
            setCurrentPage(0);
            setPageSize(sz);
          }}
          multiSort={true}
          style={{
            maxHeight: height,
            cursor: "default",
          }}
        />
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle            
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {titleName}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                margin="dense"
                variant="outlined"
                id="industryName"
                size="small"
                label="Industry name"
                type="text"
                fullWidth
                value={formik.values.industryName}
                name="industryName"
                onChange={formik.handleChange}
                helperText={
                  formik.touched.industryName && formik.touched.industryName
                    ? formik.errors.industryName
                    : ""
                }
                error={
                  formik.touched.industryName && formik.errors.industryName
                }
              />
              <TextField
                margin="dense"
                variant="outlined"
                size="small"
                id="industryDisplayName"
                label="Industry display name"
                type="text"
                fullWidth
                value={formik.values.industryDisplayName}
                name="industryDisplayName"
                onChange={formik.handleChange}
                helperText={
                  formik.touched.industryDisplayName &&
                  formik.touched.industryDisplayName
                    ? formik.errors.industryDisplayName
                    : ""
                }
                error={
                  formik.touched.industryDisplayName &&
                  formik.errors.industryDisplayName
                }
              />

              <DialogActions>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    // disabled={isSubmitting}
                    onClick={formik.handleSubmit}
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps)(Industry);
