import { DO_LOGIN, DO_LOGOUT,USER_NOT_FOUND } from "../../reduxConfig/actionTypes";
import axios from "axios";
import firebase from "firebase/app";
import config from "../../config";

export const doLogin = ({ email, password }) => async dispatch => {
  return await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async respAuth => {
      return await axios
        .post(config.api.gatewayService + "/login/checkadmin", {
          token: respAuth.user.ma
        })
        .then(respLogin => {
          if (respLogin.data.responseStatus === "USERNOTFOUND") {
            return "usernotfound";
          } else if (respLogin.data.responseStatus === "LOGIN_OK") {
            localStorage.setItem("token", respLogin.data.data.token);
            localStorage.setItem("firebasetoken", respAuth.user.ma);
            dispatch({
              type: DO_LOGIN,
              payload: {
                user: respLogin.data.user,
                token: respLogin.data.token
              }
            });
            return true;
          } else {
            return false;
          }
        })
        .catch(err => {
          return false;
        });
    })
    .catch(error => {
      console.log(error);
      return "usernotfound";
    });
};

export const doLogout = () => async dispatch => {
  return await firebase
    .auth()
    .signOut()
    .then(data => {
      localStorage.clear();
      dispatch({ type: DO_LOGOUT, payload: { username: "", ...data } });
      return true;
    })
    .catch(function(error) {
      // An error happened
      console.log(error);
      return false;
    });
};

export const checkToken = () => async dispatch => {
  localStorage.getItem("firebasetoken", async (err, value) => {
    if (err) {
      dispatch({ type: DO_LOGOUT, payload: { username: "" } });
    } else {
      await axios
        .post(config.api.gatewayService + "/login/checkadmin", {
          token: value
        })
        .then(respLogin => {
          if (respLogin.data.success) {
            localStorage.setItem("token", respLogin.data.token);
            dispatch({
              type: DO_LOGIN,
              payload: {
                user: respLogin.data.user,
                token: respLogin.data.token
              }
            });
          } else {
            dispatch({ type: DO_LOGOUT, payload: { username: "" } });
          }
        })
        .catch(err => {
          dispatch({ type: DO_LOGOUT, payload: { username: "" } });
        });
    }
  });
};

export const appLogin=(data) =>{
  return (dispatch) =>
    dispatch({
      type: DO_LOGIN,
      payload: data,
    });
}

export const userNotFound=()=> {
  return (dispatch) =>
    dispatch({
      type: USER_NOT_FOUND
    });
}
