import gql from "graphql-tag";

export const signupPlansPaginationAndGroupByRootQuery = gql`
  query signupPlansPaginationAndGroupByRootQuery(
    $searchtext: String
    $sortedBy: [SignupPlanListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    signupPlansPaginationAndGroupBy(
      searchtext: $searchtext
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      signupPlans {
        id
        industryId
        planName
        isPublished
        industryName
        industryDisplayName
        signupPlanPriceList {
          id
          planId
          renewal
          commitment
          price
          createdDatetime
          createdBy
          isActive
          isDeleted
        }
        createdDatetime
        createdBy
      }
      rowCount
    }
  }
`;

export const signupPlanDetailsByIdRootQuery = gql`
  query signupPlanDetailsByIdRootQuery($signupPlanId: Int) {
    signupPlanDetailsById(signupPlanId: $signupPlanId) {
      id
      industryId
      planName
      createdDatetime
      createdBy
      signupPlanPriceList {
        id
        planId
        renewal
        commitment
        price
        createdDatetime
        createdBy
        isActive
        isDeleted
      }
      industryDetail {
        id
        industryDisplayName
        industryName
      }
      isPublished
      signupPlans {
        id
        signupPlanId
        moduleId
        price
        createdDatetime
        createdBy
        isAddonModule
        modules {
          id
          moduleName
        }
      }
    }
  }
`;

export const signupPlanModuleBySignupPlanIdRootQuery = gql`
  query signupPlanModuleBySignupPlanId(
    $signupPlanId: Int
    $sortedBy: [SignupPlanModuleListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    signupPlanModuleBySignupPlanId(
      signupPlanId: $signupPlanId
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      signupPlanModules {
        id
        signupPlanId
        moduleId
        price
        moduleName
        moduleDisplayName
        createdDatetime
        createdBy
        isDelete
        isAddonModule
      }
      rowCount
    }
  }
`;

export const getAllModulesRootQuery = gql`
  query getAllModules {
    getAllModules {
      id
      moduleName
      moduleDisplayName
    }
  }
`;

export const addModuleToSignuPlanMutation = gql`
  mutation addModuleToSignuPlan(
    $signupModuledata: AddModuleToSignupPlanInputType
  ) {
    addModuleToSignuPlan(signupModuledata: $signupModuledata) {
      id
      signupPlanId
      moduleId
      moduleName
    }
  }
`;

export const signupPlanResourcesBySignupPlanIdRootQuery = gql`
  query signupPlanResourcesBySignupPlanId(
    $signupPlanId: Int
    $sortedBy: [SignupPlanResourcesListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    signupPlanResourcesBySignupPlanId(
      signupPlanId: $signupPlanId
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      signupPlanResources {
        id
        signupPlanId
        includedCount
        maxCount
        ratio
        resetCycle
        resourceType
        pricePerAdditionalCount
        resourceName
        createdDatetime
        createdBy
        isDelete
      }
      rowCount
    }
  }
`;

export const addResourceToSignuPlanResourcesMutation = gql`
  mutation addResourceToSignuPlanResources(
    $signupPlanResourcedata: RsourceToSignupPlanResourceInputType
  ) {
    addResourceToSignuPlanResources(
      signupPlanResourcedata: $signupPlanResourcedata
    ) {
      id
      signupPlanId
      resourceName
      resourceType
      resetCycle
      includedCount
      ratio
      pricePerAdditionalCount
      maxCount
    }
  }
`;

export const signupPlanPriceListBySignupPlanIdRootQuery = gql`
  query signupPlanPriceListBySignupPlanId(
    $signupPlanId: Int
    $sortedBy: [SignupPlanPriceListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    signupPlanPriceListBySignupPlanId(
      signupPlanId: $signupPlanId
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      signupPlanPrices {
        id
        planId
        renewal
        commitment
        uuid
        price
        createdDatetime
        createdBy
        isActive
        isDeleted
      }
      rowCount
    }
  }
`;

export const addPriceToSignuPlanPricelistMutation = gql`
  mutation addPriceToSignuPlanPricelist(
    $signupPlanPricedata: AddPriceToSignupPlanPricelistInputType
  ) {
    addPriceToSignuPlanPricelist(signupPlanPricedata: $signupPlanPricedata) {
      id
      planId
      renewal
      commitment
      price
      createdDatetime
      createdBy
      isActive
    }
  }
`;

export const priceDetailsByIdRootQuery = gql`
  query priceDetailsByIdRootQuery($priceId: Int) {
    priceDetailsById(priceId: $priceId) {
      id
      planId
      renewal
      commitment
      price
      createdDatetime
      createdBy
      isActive
      isDeleted
    }
  }
`;

export const editPriceToSignuPlanPricelistMutation = gql`
  mutation editPriceToSignuPlanPricelist(
    $signupPlanPricedata: AddPriceToSignupPlanPricelistInputType
  ) {
    editPriceToSignuPlanPricelist(signupPlanPricedata: $signupPlanPricedata) {
      id
      planId
      renewal
      commitment
      price
      createdDatetime
      createdBy
      isActive
    }
  }
`;

export const industryQuery = gql`
  query industryQuery {
    industry {
      id
      industryName
      industryDisplayName
    }
  }
`;

export const addPlanSignuPlanMutation = gql`
  mutation addPlanSignuPlan($signupPlandata: signupPlanInputType) {
    addPlanSignuPlan(signupPlandata: $signupPlandata) {
      id
      industryId
      planName
      isPublished
    }
  }
`;

export const getAllFuncationalityByModuleId = gql`
query getAllFuncationalityByModuleId($moduleId: Int) {
    getAllFuncationalityByModuleId(moduleId: $moduleId) {
      id
      functionalityName
      functionalityDisplayName
    }
  }
`;