import {
  GET_ALL_MAINTENANCES,
  DELETE_MAINTENANCE,
  ADD_NEW_MAINTENANCE,
  EDIT_MAINTENANCE
} from "../../reduxConfig/actionTypes";

let defaultState = [];

export default function(state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_MAINTENANCES:
      return action.payload;
    case DELETE_MAINTENANCE:
      return state.filter(i => i.id !== action.payload);
    case ADD_NEW_MAINTENANCE:
      return [...state, action.payload];
    case EDIT_MAINTENANCE:
      return state.map(item => {
        if (item.id !== action.payload.id) {
          // This isn't the item we care about - keep it as-is
          return item;
        }
        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload
        };
      });
    default:
      return state;
  }
}
