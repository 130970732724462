import React, { Component } from "react";
import SplashScreen  from "./pages/common/SplashScreen";
import firebaseService from "./lib/firebaseService";
import {checkToken,appLogin,doLogout,userNotFound} from "./pages/login/loginActions"
import { connect } from "react-redux";


class Auth extends Component {
    state = {
        waitAuthCheck: true,
    };

    firebaseCheck = () =>
        new Promise((resolve) => {
            firebaseService.init((success) => {
                if (!success) {
                    resolve();
                }
            });
            firebaseService.onAuthStateChanged((authUser) => {
                if (authUser) {
                    firebaseService.getUserData(authUser).then((user) => {
                        // console.log("=======23",user.responseStatus)
                        if(user.responseStatus === "USERNOTFOUND"){
                            this.props.userNotFound(user);
                        }else{
                            this.props.appLogin({
                                user: user.data.userDetail,
                                token: user.data.token
                            })  
                        }
                        // this.props.toastManager.add("Logged In Successfully", {
                        //     appearance: "success",
                        //     autoDismiss: true,
                        //     pauseOnHover: false
                        // });
                        resolve(user);
                    },
                    (error) => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        return Promise.resolve();
    });
    

    checkFirebaseAuth = () => {
        this.firebaseCheck().then((data) => {
            this.setState({ waitAuthCheck: false });
        });
    };

    componentDidMount() {
        this.checkFirebaseAuth();
    }

    render() {
        return this.state.waitAuthCheck ? (
        <SplashScreen />
        ) : (
        <React.Fragment children={this.props.children} />
        );
    }
}

const mapStateToProps = ({ login }) => {
    return { login };
};
export default connect(
    mapStateToProps,
    {
        checkToken,
        appLogin,
        doLogout,
        userNotFound
    }
)(Auth);
