import gql from "graphql-tag";

export const getSubscriptionJobsRootQuery = gql`
  query getSubscriptionJobsRootQuery {
    subscriptionJobs {
      jobName
      lastJobRunDatetime
    }
  }
`;

export const addSubscriptionJobsMutation = gql`
  mutation addSubscriptionJobs($subscriptionJobData: SubscriptionJobInputType) {
    addSubscriptionJobs(subscriptionJobData: $subscriptionJobData) {
      jobName
    }
  }
`;
