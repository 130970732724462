module.exports = {
  api: {
    graphqlService: "https://app.sbizzl.com/graphql",
    graphqlWs: "wss://app.sbizzl.com/graphql",
    // iamService: "https://app.sbizzl.com/iam",
    // saService: "https://app.sbizzl.com/sa",
    // hrService: "https://app.sbizzl.com/hr",
    gatewayService: "https://app.sbizzl.com/gtw",
  },
};
//exports prod files
