export const DO_LOGIN = "do_login";
export const DO_LOGOUT = "do_logout";
export const USER_NOT_FOUND = "user_not_found";
export const GET_ALL_CUSTOMERS = "get_all_customers";
export const GET_ALL_SUBSCRIPTIONS = "get_all_subsctiption";
export const ADD_NEW_SUBSCRIPTION = "add_new_subscription";
export const DELETE_SUBSCRIPTION = "delete_subsctiption";
export const EDIT_SUBSCRIPTION = "edit_subscription";

export const GET_ALL_MULTILINGUAL = "get_all_multilingual";
export const DELETE_MULTILINGUAL = "delete_multilingual";
export const ADD_NEW_MULTILINGUAL = "add_new_multilingual";
export const EDIT_MULTILINGUAL = "edit_multilingual";

export const GET_ALL_MAINTENANCES = "get_all_maintenances";
export const ADD_NEW_MAINTENANCE = "add_new_maintenance";
export const DELETE_MAINTENANCE = "delete_maintenance";
export const EDIT_MAINTENANCE = "edit_maintenance";
