import React from "react";

export default function Spinner() {
  return (
    <>
      <div className="overlay" />
      <div className="centerProgress spinner-wrapper jash">
        <div className="spinner">
          <div className="inner">
            <div className="gap" />
            <div className="left">
              <div className="half-circle" />
            </div>
            <div className="right">
              <div className="half-circle" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
