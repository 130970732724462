import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider as StoreProvider } from "react-redux";
import rootReducer from "./reduxConfig/rootReducer";
import AppRoot from "./AppRoot";
import "assets/css/material-dashboard-react.css?v=1.6.0";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { ApolloProvider } from "react-apollo";
import config from "./config";
import { ToastProvider } from "react-toast-notifications";
import Auth from "./Auth";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const link = createUploadLink({
  uri: config.api.graphqlService,
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("token");
  // console.log("###########1", token);
  return {
    headers: {
      ...headers,
      token: token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  // defaultOptions: defaultOptions
});

const enhancer =
  process.env.NODE_ENV === "development"
    ? composeEnhancers(
        applyMiddleware(thunk)
        // other store enhancers if any
      )
    : applyMiddleware(thunk);

const store = createStore(rootReducer, enhancer);

ReactDOM.render(
  <StoreProvider store={store}>
    <ApolloProvider client={client}>
      <Auth>
        <ToastProvider placement="bottom-right">
          <AppRoot />
        </ToastProvider>
      </Auth>
    </ApolloProvider>
  </StoreProvider>,

  document.getElementById("root")
);
