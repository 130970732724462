import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
export default function ConfirmDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    if (!props.disabled) {
      setOpen(true);
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    if (!props.disabled) {
      setOpen(false);
    }
  };

  const handleYes = (e) => {
    e.stopPropagation();
    props.onConfirm();
    setOpen(false);
  };

  const handleNo = (e) => {
    e.stopPropagation();
    props.onReject();
    setOpen(false);
  };
  // console.log(props.children);
  return (
    <>
      <div
        onClick={handleClickOpen}
        style={props.disabled ? { filter: "saturate(0)", opacity: "0.5" } : {}}
        className={props.className}
      >
        {props.children}
      </div>

      <Dialog
        open={open}
        onClose={handleNo}
        onEscapeKeyDown={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className="dialogTitle"
          style={{
            padding: 5,
            backgroundColor: "#3F51B5",
            color: "#fff",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ marginLeft: 8, fontSize: 18 }}>
              {props.title}
            </Typography>
            <IconButton
              aria-label="close"
              // className="closeButton"
              onClick={handleCancel}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>{" "}
          </div>
        </DialogTitle>
        {props.body && (
          <DialogContent className="p-0" style={{ background: "#fff" }}>
            <div className="p-16">
              <Typography
                // variant="h5"
                id="alert-dialog-description"
                style={{ color: "#192d3e", fontSize: 18 }}
              >
                {props.body}
              </Typography>
            </div>
          </DialogContent>
        )}

        <DialogActions style={{ background: "#fff" }}>
          <Button onClick={handleNo} variant="contained" color="default">
            {props.firstButton}
          </Button>
          <Button
            onClick={handleYes}
            color="primary"
            autoFocus
            variant="contained"
          >
            {props.secondButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
