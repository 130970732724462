import React, { useEffect, useState } from "react";
import {
  Grid,
  DialogActions,
  Button,
  Select as MUISelect,
  ListItemText,
  Typography,
  Paper,
} from "@material-ui/core";
import { useQuery, useMutation } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllFuncationalityByModuleId } from "./signupPlansGQLQuery";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const ModuleFuncationalityListModal = (props) => { 
  const {
    data: getAllFuncationalityData
  } = useQuery(getAllFuncationalityByModuleId, {
    variables: {
      moduleId: parseInt(props.moduleData.id),
    },
    fetchPolicy: "network-only",
  });
  
  const { classes } = props;
  return (
    <form>
        <Grid container spacing={2} >
              <Grid item xs={12}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25
                              }}
                            >
                              Module Name
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400
                              }}
                            > 
                            {props.moduleData.name.charAt(0) +
              props.moduleData.name.slice(1).toLowerCase()}                             
                            </Typography>
                          }
                        />
                        </Grid>

                        <Grid item xs={12}>
                        <Paper>
                        <TableContainer
                        classes={{
                          root:"tableContainerRoot"
                        }}
                        >
                        <Table
                  stickyHeader aria-label="sticky table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width: "50%"}}>{"Funcationality Name"}</TableCell>
                      <TableCell style={{width: "50%"}}>{"Funcationality Display Name"}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    // style={{
                    //   maxHeight: "300px",
                    //   overflowY: "auto"
                    // }}
                  >
                    {getAllFuncationalityData && getAllFuncationalityData.getAllFuncationalityByModuleId.length > 0 ? (
                      getAllFuncationalityData.getAllFuncationalityByModuleId.map((i, idx) => (
                          <TableRow key={i.id}>
                            <TableCell style={{width: "50%"}}>
                              {i.functionalityName}
                            </TableCell>
                            <TableCell style={{width: "50%"}}>
                              {i.functionalityDisplayName}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ padding: "30px" }}
                        >
                          {"This module dose not have any funcationality"}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                </TableContainer>
                </Paper>
                        </Grid>
          </Grid>
        </form>
  );
};

ModuleFuncationalityListModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  }
});

export default withStyles(styles)(
  connect(mapStateToProps)(ModuleFuncationalityListModal)
);
