import gql from "graphql-tag";


export const getReleaseNotesRootQuery = gql`
  query releaseNotesRootQuery($releaseType: String, $limit: Int, $offset: Int) {
    releaseNotes(releaseType: $releaseType, limit: $limit, offset: $offset) {
      releaseNote {
        id
        releaseDate
        releaseType
        description
      }
      rowCount
    }
  }
`;


export const addReleaseNotesMutation = gql`
  mutation addReleaseNotes($ReleaseNotesData: ReleaseNoteInputType) {
    addReleaseNotes(ReleaseNotesData: $ReleaseNotesData) {
    id
    releaseDate
    releaseType
    description
    }
  }
`;


export const editReleaseNotesMutation = gql`
  mutation editReleaseNotes($ReleaseNotesData: ReleaseNoteInputType) {
    editReleaseNotes(ReleaseNotesData: $ReleaseNotesData) {
    id
    releaseDate
    releaseType
    description
    }
  }
`;

export const deleteReleaseNotesMutation = gql`
  mutation deleteReleaseNotes($releaseNotesId: Int) {
    deleteReleaseNotes(releaseNotesId: $releaseNotesId) {
    id
    releaseDate
    releaseType
    description
    }
  }
`;
