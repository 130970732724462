import gql from "graphql-tag";

export const adminUsersPaginationAndGroupByRootQuery = gql`
  query adminUsersPaginationAndGroupByRootQuery(
    $searchtext: String
    $sortedBy: [adminUsersListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    adminUsersPaginationAndGroupBy(
      searchtext: $searchtext
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      superAdminUsers {
        id
        adminClientId
        email
        mName
        lName
        fName
        timezone        
        gender
        dob
        createdDatetime
        createdBy
      }
      rowCount
    }
  }
`;

export const addSuperAdminUserMutation = gql`
  mutation addSuperAdminUser(
    $userData: userDataInputType
  ) {
    addSuperAdminUser(userData: $userData) {
      id
     email
    }
  }
`;

export const deleteSuperAdminUserMutation = gql`
  mutation deleteSuperAdminUser($adminCombId: Int) {
    deleteSuperAdminUser(adminCombId: $adminCombId) {
        id
        adminClientId
        email
        mName
        lName
        fName
        timezone
        gender
        dob
        createdDatetime
        createdBy
    }
  }
`;