import gql from "graphql-tag";

export const getMultilingualQuery = gql`
  query getMultilingualQuery($limit: Int, $offset: Int, $searchtext: String) {
    multilingual(limit: $limit, offset: $offset, searchtext: $searchtext) {
      multilingualList {        
        lableIndustryId
        systemLabelId
        industryId
        systemLabel
        usEnglish
        ukEnglish
        spanish
        hindi
        isActive
        isDelete
        industryName
      }
      rowCount
    }
  }
`;

export const addMultilingualMutation = gql`
  mutation addMultilingual($multilingual: AddMultilingualInputType) {
    addMultilingual(multilingual: $multilingual) {
      lableIndustryId
      systemLabelId
      industryId
      systemLabel
      usEnglish
      ukEnglish
      spanish
      hindi
      isActive
      isDelete
      industryName
    }
  }
`;

export const editMultilingualMutation = gql`
  mutation editMultilingual($multilingual: [EditMultilingualInputType]) {
    editMultilingual(multilingual: $multilingual) {
      id
      lableIndustryId
      systemLabelId
      industryId
      systemLabel
      usEnglish
      ukEnglish
      spanish
      hindi
      isActive
      isDelete
      industryName
    }
  }
`;

export const deleteMultilingualMutation = gql`
  mutation deleteMultilingual($lableIndustryId: Int, $systemLabelId: Int) {
    deleteMultilingual(
      lableIndustryId: $lableIndustryId
      systemLabelId: $systemLabelId
    ) {
      lableIndustryId
      systemLabelId
      industryId
      systemLabel
      usEnglish
      ukEnglish
      spanish
      hindi
      isActive
      isDelete
      industryName
    }
  }
`;
