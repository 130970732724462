import React, { Component,useState } from "react";
import PropTypes from "prop-types";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
  Typography,
  TextField,
  Paper,
  Avatar,
  Button,
  CssBaseline,Divider,Card,CardContent
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { doLogin, doLogout, } from "./loginActions";
import { withToastManager } from "react-toast-notifications";
import { Formik, Form, } from "formik";
import * as Yup from "yup";
import {signInWithGoogle} from "./firbaseAuth";
import applogoImg from "../../assets/img/logo-lg.png"
import logoImg from "../../assets/img/logo-lg.png"
import noFoundImg from "../../assets/img/no_found-person.png"
import clsx from "clsx";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    [theme.breakpoints.up(500 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    textTransform: "capitalize"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
});

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = async data => {
    var showNotification = await this.props.doLogin({
      email: data.email,
      password: data.password
    });
    if (showNotification === true) {
      this.props.toastManager.add("Logged In Successfully", {
        appearance: "success",
        autoDismiss: true,
        pauseOnHover: false
      });
      this.props.history.push("/admin/clients")
    } else if (showNotification === "usernotfound") {
      this.setState({
        usernotFound:true
      })
      this.props.toastManager.add("User not Found", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: true,
      });
    } else {
      this.props.toastManager.add("Something went wrong", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false
      });
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes } = this.props;
    if (this.props.login.loggedIn) {
      return <Redirect to={"/admin/clients"} />;
    }
    return (
      <main className={clsx(
        classes.main,
        "loginFormWraper"
      )}>
        <CssBaseline />

        {this.props.login.userNotFound ? 
          <div className="userNotFoundBlock">
            <div className="nofoundImage">
              <img
                className="w-128"
                src={logoImg}
                alt="logo"
              />
            </div>
            <div className="nofoundContent">
              <Card className="w-full text-center">
                <CardContent>
                  <div className="text-center">
                    <img
                      alt="no_found-person"
                      src={noFoundImg}
                      className={"largeAvatar"}
                    />
                  </div>
                  <Typography variant="h5" component="h2">
                    User email is not register with sBizzl admin.
                  </Typography>
                </CardContent>
              </Card>
            </div>
            <div className="nofoundBackButton">
              <span
                 className={"backButtonLink"}
                onClick={() => {
                  this.props.doLogout()
                }}
              >
                Go back to login
              </span>
            </div>
          </div>
         : <Paper className={clsx(
          classes.paper,
          "loginFormPaper"
        )} >
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <div className="loginFormHeader">
              <img
                className="w-128"
                src={applogoImg}
                alt="logo"
              />
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string("")
                .email("Enter a valid email")
                .required("Enter your email"),
              password: Yup.string("")
                .min(6, "Password must contain at least 6 characters")
                .required("Enter your password")
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
              } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <TextField
                    error={errors.email && touched.email}
                    label="Email Address"
                    name="email"
                    className={classes.textField}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.email && touched.email && errors.email}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    error={errors.password && touched.password}
                    label="Password"
                    name="password"
                    className={classes.textField}
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                    margin="normal"
                    fullWidth
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={clsx(
                      classes.submit,
                      "defaultButton"
                    )}
                    onClick={handleSubmit}
                    >
                      Sign in
                    </Button>

                    <div className="dividerStyle">
                      <Divider className="w-100" />
                      <span
                        className="cssOrStyle"
                      >
                        OR
                      </span>
                      <Divider className="w-100" />
                    </div>
                    <div className="flex items-center justify-center">
                      <Button
                        type="button"
                        onClick={signInWithGoogle}
                        variant="contained"
                        color="primary"
                        size="large"
                        className="defaultButton"
                      >
                        <span className="signinButtonImg" />
                        Sign in with Google
                      </Button>
                    </div>
                </Form>
              );
            }}
          </Formik>
        </Paper>}
        
      </main>
    );
  }
}

LogIn.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ login }) => {
  return { login };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      doLogin,
      doLogout,
    }
  )(withToastManager(LogIn))
);
