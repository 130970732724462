/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import styles from "../assets/jss/defaultLayoutStyle";

const Sidebar = ({ ...props }) => {
  const { classes, routes } = props;
  return (
    <List className={clsx(
      classes.list,
            "drawerlist"
          )}>
      {routes.filter(i=>i.sidebarShow).map((prop, key) => {
        return (
          <NavLink
            to={prop.layout + prop.path}
            activeClassName="active"
            key={key}
          >
            <ListItem button>
              <ListItemIcon className={classes.iconCover}>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <prop.icon />
                )}
              </ListItemIcon>
              <ListItemText primary={prop.name} />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
