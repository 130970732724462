import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../routes";
import Sidebar from "./Sidebar";
import logo from "assets/img/reactlogo.png";
import TopBar from "./TopBar";
import styles from "../assets/jss/defaultLayoutStyle";
import { connect } from "react-redux";
// import { Icon} from "@material-ui/core";
import clsx from "clsx";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      // if (prop.layout === "/admin") {
      //   return (
      //     <Route
      //       path={prop.layout + prop.path}
      //       component={prop.component}
      //       key={key}
      //     />
      //   );
      // }

      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          exact={prop.exact ? true : false}
        />
      );
    })}
  </Switch>
);

class DefaultLayout extends React.Component {
  state = {
    open: true
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, ...rest } = this.props;
    if (this.props.login.redirectToLogin === true) {
      return <Redirect to={"/"} />;
    }

    return (
      <div className={classes.root}
      >
        <CssBaseline />
        <TopBar
          routes={routes}
          open={this.state.open}
          handleDrawerOpen={this.handleDrawerOpen}
          {...rest}
        />

        <div className="drawerWraper">
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose
              )
            }}
            open={this.state.open}
          >
            <h3 className="drawerTitle">
              sBizzl Admin
            </h3>
            <Divider />
            <Sidebar
              routes={routes}
              logoText={"sBizzl"}
              logo={logo}
              image={this.state.image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color={this.state.color}
              {...rest}
            />
          </Drawer>
        </div>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>{switchRoutes}</div>
        </main>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ login }) => {
  return { login };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(DefaultLayout)
);
