import gql from "graphql-tag";

export const getScInvoiceBillingListByFilterPaginationAndGroupByQuery = gql`
  query getScInvoiceBillingListByFilterPaginationAndGroupByQuery(
    $sortedBy: [SCInvoiceListSortedByType]
    $limit: Int
    $offset: Int
    $searchtext: String
    $filterData: SCInvoiceFilterDataType
  ) {
    scInvoiceBillingListByFilterPaginationAndGroupByQuery(
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
      searchtext: $searchtext
      filterData: $filterData
    ) {
      scInvoiceBilling {
        id
        systemClientId
        clientName
        customerName
        invoiceCode
        status
        invoiceNote
        invoiceDate
        paymentDate
        dueDate
        subTotal
        discountAfterTax
        discountType
        discountPercentage
        discountAmount
        totalAmount
        depositAmount
        currencyConfig
        rowCount
      }
      rowCount
    }
  }
`;

export const downloadScInvoicePdf = gql`
  query downloadScInvoicePdf($id: Int, $systemClientId: Int) {
    downloadScInvoicePdf(id: $id, systemClientId: $systemClientId)
  }
`;
