import firebaseConfig from "./firebaseConfig";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";
import config from "../../src/config"
import axios from "axios";

class firebaseService {
  init(success) {
    if (
      Object.entries(firebaseConfig).length === 0 &&
      firebaseConfig.constructor === Object
    ) {
      if (process.env.NODE_ENV === "development") {
        console.warn(
          "Missing Firebase Configuration at src/app/services/firebaseService/firebaseServiceConfig.js"
        );
      }
      success(false);
      return;
    }
    if (firebase.apps.length) {
      return;
    }
    let firebaseApp = firebase.initializeApp(firebaseConfig);
    this.db = firebase.database();
    this.auth = firebase.auth();
    this.analytics = firebase.analytics(firebaseApp);
    
    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
    }
    success(true);
  }
  resetPasswordFirstTimeLogin = (password) => {
    return this.auth.currentUser.updatePassword(password);
  };

  forgetPassword = (email) => {
    return this.auth.sendPasswordResetEmail(email);
  };

  getUserData = (data) => {
    if (!firebase.apps.length) {
      return;
    }
    return new Promise((resolve, reject) => {
        this.auth.currentUser.getIdToken().then(async (token) => {
          await axios
          .post(config.api.gatewayService + "/login/checkadmin", {
            token
          })
          .then(respLogin => {
            if (respLogin.data.responseStatus === "USERNOTFOUND") {
                resolve(respLogin.data);
            } else if (respLogin.data.responseStatus === "LOGIN_OK") {
              localStorage.setItem("token", respLogin.data.data.token);
              localStorage.setItem("firebasetoken", token);
              resolve(respLogin.data);
            } else {
              resolve(false);
            }
          })
          .catch(err => {
            reject(err)
          });
    
        }).catch((err)=>{
          console.group("errr",err)
        })})
  };

  updateUserData = (user) => {
    if (!firebase.apps.length) {
      return;
    }
    return this.db.ref(`users/${user.uid}`).set(user);
  };

  onAuthStateChanged = (callback) => {
    if (!this.auth) {
      return;
    }
    this.auth.onAuthStateChanged(callback);
  };

  signOut = () => {
    if (!this.auth) {
      return;
    }
    this.auth.signOut().then(
      () => {
        console.log("Signed Out");
      },
      (error) => {
        console.log("Sign Out Error", error);
      }
    );
  };

  sendVerificationEmail = () => {
    return this.auth.currentUser.sendEmailVerification();
  };
}

const instance = new firebaseService();

export default instance;
