let getItem = (props, qry) => {
    if (typeof props !== "object") {
      return false;
    }
    const { state } = props.location;
    if (state && state[qry]) {
      return state[qry];
    } else {
      return null;
    }
  };
  
  let setItem = (props, qry, data, overwrite) => {
    if (typeof props !== "object") {
      return false;
    }
    try {
      const { state } = props.location;
  
      // console.log("qry, data", qry, data);
      if (typeof state === "object") {
        props.history.replace({ state: { ...state, [qry]: data } });
        return true;
      } else {
        state = { [qry]: data };
        props.history.replace({ state: { [qry]: data } });
        return true;
      }
    } catch (er) {
      return false;
    }
  };
  
  let clearAll = (props, qry, data, overwrite) => {
    if (typeof props !== "object") {
      return false;
    }
    try {
      console.log("all state history clear.....");
      props.history.replace({ state: {} });
  
      return true;
    } catch (er) {
      return false;
    }
  };
  
  export default { get: getItem, set: setItem, clear: clearAll };
  