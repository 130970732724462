import { DO_LOGIN, DO_LOGOUT,USER_NOT_FOUND } from "../../reduxConfig/actionTypes";

let defaultState = { loggedIn: false,userNotFound:false };

export default function(state = defaultState, action) {
  switch (action.type) {
    case DO_LOGIN:
      return { loggedIn: true, redirectToLogin: false, ...action.payload };
    case DO_LOGOUT:
      return { loggedIn: false, redirectToLogin: true, ...action.payload };
    case USER_NOT_FOUND: {
      return {
        ...state,
        userNotFound: true,
      };
    }
    default:
      return state;
  }
}
