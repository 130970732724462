import React, { useState, useEffect } from "react";
import {
  sbizzlMaintenanceByFilterAndPaginationQuery,
  deleteSbizzlMaintenanceMutation,
  addSbizzlMaintenanceMutation,
  editSbizzlMaintenanceMutation,
  updateSbizzlMaintenanceCompletedMutation,
} from "./sbizzlMaintenanceGqlQuery";
import { withStyles } from "@material-ui/core/styles";
import ConfirmDialog from "../common/ConfirmDialog";
import CloseIcon from "@material-ui/icons/Close";
import ActionOptionsButton from "../common/ActionOptionsButton";
import {
  Typography,
  Popover,
  Chip,
  FormControl,
  DialogContent,
  Select as MUISelect,
  Button,
  Grid,
  Input,
  Dialog,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import Select from "react-select";
import { useQuery, useMutation } from "react-apollo";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import queryHistory from "../../lib/queryHistroy";
import DraggableTable from "../common/DraggableTable";
import { industryQuery } from "../plans/signupPlansGQLQuery";
import AddEditMaintenanceModal from "./AddEditMaintenanceModal";

const defaultPageSize = 20;
const defaultPage = 1;

const defaultSorted = [
  {
    id: "client_name",
    desc: false,
  },
];

const SbizzlMaintenance = (props) => {
  const columns = [
    {
      Header: "Start Date",
      Cell: (props) => {
        return (
          <span>
            {moment(props.original.fromDatetime).format("YYYY-MM-DD HH:mm A")}
          </span>
        );
      },
    },
    {
      Header: "End Date",
      Cell: (props) => {
        return (
          <span>
            {moment(props.original.toDatetime).format("YYYY-MM-DD HH:mm A")}
          </span>
        );
      },
    },
    {
      Header: "Note",
      Cell: (props) => {
        return <span>{props.original.note}</span>;
      },
    },
    {
      Header: "Active",
      Cell: (props) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.original.isActive ? 1 : 0}
          </span>
        );
      },
    },
    {
      Header: "Completed",
      Cell: (props) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.original.isCompleted ? 1 : 0}
          </span>
        );
      },
    },
    {
      Header: "Action",
      width: 75,
      Cell: (props) => {
        let options = [];
        options.push({
          label: "Edit",
          callBack: (ev) => {
            // ev.stopPropagation();
            editMaintenance(props.original);
          },
        });
        options.push({
          label: (
            <ConfirmDialog
              title={"Confirm Delete"}
              body={"Are you sure you want to delete this record?"}
              open={true}
              firstButton={"Cancel"}
              secondButton={"Delete"}
              onConfirm={() => {
                setDeleteObj(props.original);
                let deleteObj = props.original;
                deleteMaintenance(props.original.id);
              }}
              onReject={() => {}}
            >
              Delete
            </ConfirmDialog>
          ),
        });
        options.push({
          label: (
            <ConfirmDialog
              title={"Confirm"}
              body={"Are you sure you want to proceed?"}
              open={true}
              firstButton={"Cancel"}
              secondButton={"Confirm"}
              onConfirm={() => {
                handleConfirm(parseInt(props.original.id));
              }}
              onReject={() => {}}
            >
              Confirm
            </ConfirmDialog>
          ),
        });
        return <ActionOptionsButton options={options} />;
      },
    },
  ];

  const deleteMaintenance = (id) => {
    deleteSbizzlMaintenance({
      variables: {
        sbizzlMaintenanceId: id,
      },
    })
      .then((item) => {
        refetch().then((i)=>{
          setSbizzlMaintenanceList(
            i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
              .sbizzlMaintenanceList
          );
          setPages(
            i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
              .sbizzlMaintenanceList
              ? Math.ceil(
                  i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
                    .rowCount / defaultPageSize
                )
              : 1
          );
        })
        // this.props.sbizzlMaintenanceByFilterAndPaginationQuery.refetch();
        // this.props.toastManager.add("Delete Successfully", {
        //   appearance: "error",
        //   autoDismiss: true,
        //   pauseOnHover: false
        // });
        // console.log(item);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirm = (paramId) => {
    if (paramId > 0) {
      updateSbizzlMaintenanceCompleted({
        variables: {
          id: paramId,
        },
      })
        .then((data) => {
          refetch().then((i) => {
            setSbizzlMaintenanceList(
              i &&
                i.data &&
                i.data.sbizzlMaintenanceByFilterAndPagination &&
                i.data.sbizzlMaintenanceByFilterAndPagination
                  .sbizzlMaintenanceList.length > 0
                ? cloneDeep(
                    i.data.sbizzlMaintenanceByFilterAndPagination
                      .sbizzlMaintenanceList
                  )
                : []
            );
            setPages(
              i &&
                i.data &&
                i.data.sbizzlMaintenanceByFilterAndPagination &&
                i.data.sbizzlMaintenanceByFilterAndPagination
                  .sbizzlMaintenanceList
                ? Math.ceil(
                    i.data.sbizzlMaintenanceByFilterAndPagination.rowCount /
                      defaultPageSize
                  )
                : 1
            );
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const editMaintenance = (values) => {
    setMaintenanceObj(values);
    setOpen(true);
    setHeaderDialogTitle("Edit Maintenance");
    // this.setState({
    //   initForm: {
    //     id: values.id,
    //     fromDatetime: new Date(values.fromDatetime),
    //     toDatetime: new Date(values.toDatetime),
    //     isCompleted: values.isCompleted,
    //     isActive: values.isActive,
    //     note: values.note
    //   },
    //   open: true,
    //   headerDialogTitle: "Edit Maintenance"
    // });
  };
  const {
    data: sbizzlMaintenanceQueryData,
    loading: sbizzlMaintenanceQueryLoading,
    refetch,
    error: sbizzlMaintenanceQueryError,
    fetchMore: sbizzlMaintenanceQueryFetchMore,
  } = useQuery(sbizzlMaintenanceByFilterAndPaginationQuery, {
    variables: {
      checkCompletedMaintanance:false,
      limit: defaultPageSize,
      offset: defaultPage,
    },
    // onCompleted: (data) => {
    //   setSbizzlMaintenanceList(
    //     data &&
    //       data.sbizzlMaintenanceByFilterAndPagination &&
    //       data.sbizzlMaintenanceByFilterAndPagination.sbizzlMaintenanceList
    //         .length > 0
    //       ? cloneDeep(
    //           data.sbizzlMaintenanceByFilterAndPagination.sbizzlMaintenanceList
    //         )
    //       : []
    //   );
    //   setPages(
    //     data &&
    //       data.sbizzlMaintenanceByFilterAndPagination &&
    //       data.sbizzlMaintenanceByFilterAndPagination.sbizzlMaintenanceList
    //       ? Math.ceil(
    //           data.sbizzlMaintenanceByFilterAndPagination.rowCount /
    //             defaultPageSize
    //         )
    //       : 1
    //   );
    // },
    fetchPolicy: "network-only",
  });
  const { classes } = props;
  const [deleteSbizzlMaintenance] = useMutation(
    deleteSbizzlMaintenanceMutation
  );
  const [updateSbizzlMaintenanceCompleted] = useMutation(
    updateSbizzlMaintenanceCompletedMutation
  );
  useEffect(() => {    
    if (
      sbizzlMaintenanceQueryData &&
      sbizzlMaintenanceQueryData.sbizzlMaintenanceByFilterAndPagination &&
      sbizzlMaintenanceQueryData.sbizzlMaintenanceByFilterAndPagination
        .sbizzlMaintenanceList
    ) {
      setSbizzlMaintenanceList(
        sbizzlMaintenanceQueryData.sbizzlMaintenanceByFilterAndPagination
          .sbizzlMaintenanceList
      );
      setPages(
        sbizzlMaintenanceQueryData.sbizzlMaintenanceByFilterAndPagination
          .sbizzlMaintenanceList
          ? Math.ceil(
              sbizzlMaintenanceQueryData.sbizzlMaintenanceByFilterAndPagination
                .rowCount / defaultPageSize
            )
          : 1
      );
    }    
  }, [sbizzlMaintenanceQueryData]);

  const fetchData = (state, instance) => {
    setSortedArray(state.sorted);
    getData(state.pageSize, state.page + 1);
  };

  const getData = async (pageSize, page) => {
 
    let newQueryVariables = {
      checkCompletedMaintanance: checkCompletedMaintanance,
      limit: pageSize,
      offset: page,
    };
    let historyFilters = {
      checkCompletedMaintanance: checkCompletedMaintanance,
      limit: pageSize,
      offset: page,
    };
    queryHistory.set(
      props,
      "getSystemClientsPaginationAndGroupByQuery",
      historyFilters
    );
    refetch(newQueryVariables).then((i)=>{
      setSbizzlMaintenanceList(
        i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
          .sbizzlMaintenanceList
      );
      setPages(
        i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
          .sbizzlMaintenanceList
          ? Math.ceil(
              i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
                .rowCount / defaultPageSize
            )
          : 1
      );
    });
  };
  const divRef = React.useRef();
  let [confirmId, setConfirmId] = useState(0);
  let [deleteObj, setDeleteObj] = useState({});
  const [sortedArray, setSortedArray] = useState(defaultSorted);
  const [sbizzlMaintenanceList, setSbizzlMaintenanceList] = useState([]);
  const [pages, setPages] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(0);
  let [checkCompletedMaintanance, setCheckCompletedMaintanance] = useState(
    false
  );
  const [maintenanceObj, setMaintenanceObj] = useState({});
  var height = window.innerHeight - 150;
  const [open, setOpen] = useState(false);
  const [headerDialogTitle, setHeaderDialogTitle] = useState("Add Maintenance");
  const handleClickOpen = () => {
    setOpen(true);
    setHeaderDialogTitle("Add Maintenance");
  };
  let [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setMaintenanceObj({});
    setOpen(false);
  };
  const handleAnchorElMenuOpen = (event) => {
    setAnchorEl(divRef.current);
  };
  const handleAnchorElMenuClose = () => {
    setAnchorEl(null);
  };
  let openPopover = Boolean(anchorEl);

  const handleCheckCompletedMaintanance = (e) => {
    setCheckCompletedMaintanance(e.target.checked);
  };

  const clearAllData = async () => {
    queryHistory.clear(props);
    handleAnchorElMenuClose();
    checkCompletedMaintanance = false;
    setCheckCompletedMaintanance(false);
    await getData(defaultPageSize, defaultPage);
  };
  return (
    <div>
      {sbizzlMaintenanceQueryLoading && <Spinner />}
      <div className="reactTableWraper">
        <div className="header-Wraper justifyContentEnd">
          <div className="header-Wraper-right" />
          <div className="header-Wraper-right" />
          <div className="header-Wraper-right">
            <div className="header-Wraper-filterDiv">
              <Button
                ref={divRef}
                aria-describedby={"popover"}
                variant="contained"
                color="primary"
                onClick={handleAnchorElMenuOpen}
              >
                <Icon>filter_alt</Icon>
              </Button>
              <div className="popoverOverflow">
                <Popover
                  id="popover"
                  open={openPopover}
                  anchorEl={anchorEl}
                  classes={{
                    paper: "popoverOverflow",
                  }}
                  onClose={handleAnchorElMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div className="popoverContentwraper">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 6,
                      }}
                    >
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAnchorElMenuClose();
                        }}
                        style={{ color: "#0077C5", cursor: "pointer" }}
                      >
                        Cancel
                      </a>
                    </div>
                    <div className="popoverFormDatePickerWraper">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div
                            style={{ position: "relative", zIndex: 999 }}
                            className="w-full mb-16"
                          >
                            <Typography
                              // variant="h6"
                              color="inherit"
                              className="w-full mb-8"
                              style={{ fontSize: 16, marginBottom: 6 }}
                            >
                              Completed Maintenance
                            </Typography>

                            <FormControlLabel
                              className="chekBoxLabelActive"
                              control={
                                <Checkbox
                                  style={{ marginLeft: 10, fontSize: 14 }}
                                  name="Maintenance"
                                  checked={checkCompletedMaintanance}
                                  onChange={handleCheckCompletedMaintanance}
                                  color="primary"
                                  className="checkBoxprimary"
                                />
                              }
                              label={"Maintenance"}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="popoverWraperFooter">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="cancelBtn"
                        onClick={clearAllData}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="filterBtn"
                        onClick={() => {
                          handleAnchorElMenuClose();
                          getData(defaultPageSize, defaultPage);
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          <div className="addButtonStyle" style={{ marginLeft: 8 }}>
            <Button
              size="medium"
              variant="contained"
              className="btnSmall panelAddButton p-0"
              style={{
                maxWidth: 128,
                height: 34,
                backgroundColor: "#387DFF",
              }}
              onClick={handleClickOpen}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                Add Maintenance
              </Typography>
            </Button>
          </div>
        </div>
        <DraggableTable
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={pages} // Display the total number of pages
          loading={sbizzlMaintenanceQueryLoading} // Display the loading overlay when we need it
          onFetchData={fetchData} // Request new data when things change
          data={sbizzlMaintenanceList}
          expanderDefaults={{ width: 0 }}
          columns={columns}
          // resizable={true}
          noDataText="No Record Found"
          minRows={1}
          defaultSorted={sortedArray}
          pageSizeOptions={[20, 25, 50, 100]}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          page={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          onPageSizeChange={(sz) => {
            setCurrentPage(0);
            setPageSize(sz);
          }}
          multiSort={true}
          style={{
            maxHeight: height - 50,
            cursor: "default",
          }}
        />

<Dialog
        fullWidth={true}
        scroll={"paper"}
        maxWidth="xs"
        style={{ overflow: "hidden" }}
        disableBackdropClick
        open={open}
        onEscapeKeyDown={handleClose}
        onClose={handleClose}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >

        {/* <Dialog open={open} onClose={handleClose} maxWidth="sm"> */}
          <DialogTitle
            style={{
              padding: 5,
              backgroundColor: "#3F51B5",
              color: "#fff",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginLeft: 8, fontSize: 18 }}>
                {headerDialogTitle}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: "white" }} color="fff" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
          classes={{
            root: classes.dialogContentRoot,
          }}
        >
            <AddEditMaintenanceModal
              maintenanceObj={maintenanceObj}
              onSuccess={(data) => {
                refetch().then((i)=>{
                  setSbizzlMaintenanceList(
                    i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
                      .sbizzlMaintenanceList
                  );
                  setPages(
                    i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
                      .sbizzlMaintenanceList
                      ? Math.ceil(
                          i && i.data && i.data.sbizzlMaintenanceByFilterAndPagination
                            .rowCount / defaultPageSize
                        )
                      : 1
                  );
                })
              }}
              handleClose={handleClose}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

const styles = () => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});
const mapStateToProps = ({}) => {
  return {};
};

export default withStyles(styles)(connect(mapStateToProps)(SbizzlMaintenance));
