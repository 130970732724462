module.exports = {
  api: {
    graphqlService: "http://localhost:5000/graphql",
    graphqlWs: "ws://localhost:5000/graphql",
    // iamService: "http://localhost:5001",
    // saService: "http://localhost:8080",
    // hrService: "http://localhost:5002",
    // packagingService: "http://localhost:5007",
    gatewayService: "http://localhost:5012"
  }
};
