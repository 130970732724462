import gql from "graphql-tag";

export const getSystemClientQuery = gql`
  query getSystemClientQuery($id: Int) {
    systemClients(id: $id) {
      id
      clientName
      customerStatus
      updatedDatetime
      url
      industry {
        id
        industryName
        industryDisplayName
      }
      timeZone {
        id
        timeZoneCode
        timeZoneDisplayName
      }
      dateFormat {
        id
        dateFormat
        displayDateFormat
      }
      subscription {
        id
        billingCycleId
        cancellDatetime
        trialStartDate
        trialEndDate
        subscriptionStartdate
        subscriptionExpiryDate
        subscription {
          id
          subscriptionName
          description
          usersAllowed
          price
        }
        systemClient{
          id
          updatedDatetime
        }
      }
      owner {
        id
        employeeDetail {
          id
          fName
          isCompany
          companyName
          mName
          lName
          phone {
            id
            phoneNo
            isPrimary
          }
          email {
            id
            email
            isPrimary
          }
        }
      }
      createdDatetime
      project {
        createdDatetime
      }
      deal {
        createdDatetime
      }
      tasks {
        createdDatetime
      }
      customer {
        createdDatetime
      }
      estimate {
        createdDatetime
      }
      invoice {
        createdDatetime
      }
      locationComb {
        id
      systemClientId
      locationId
      ownerType
      ownerId
      addrLine1
      addrLine2
      city
      pincode
      state
      country
      }
      login {
        createdDatetime
      }
    }
  }
`;

export const getsystemClientSubscriptionResourceUsageBySystemClientIdQuery = gql`
  query systemClientSubscriptionResourceUsageBySystemClientId(
    $systemClientId: Int
  ) {
    systemClientSubscriptionResourceUsageBySystemClientId(
      systemClientId: $systemClientId
    ) {
      subscriptionId
      resource
      countAllowed
      usage
    }
  }
`;

export const getSystemClientsPaginationAndGroupByQuery = gql`
  query getSystemClientsPaginationAndGroupByQuery(
    $searchtext: String
    $filterData: SystemClientFilterDataType
    $sortedBy: [SystemClientListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    systemClientsPaginationAndGroupBy(
      searchtext: $searchtext
      filterData: $filterData
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      systemClients {
        id
        clientName
        planId
        planName
        renewal
        industryId
        price
        industryName
        industryDisplayName
        trialStartDate
        trialEndDate
        subscriptionStartDate
        subscriptionExpiryDate
        customerStatus
        contactId
        phoneNo
        email  
        ownerName
        createdDatetime
        lastLoginDate
      }      
      rowCount
    }
  }
`;

export const getSystemClientSubscriptionByIdRootQuery = gql`
  query getSystemClientSubscriptionByIdRootQuery($systemClientId: Int) {
    getSystemClientSubscriptionById(systemClientId: $systemClientId) {
      id
      planId
      scSubscriptionPricelistId
      price
      renewal
      trialStartDate
      trialEndDate
      subscriptionStartdate
      subscriptionExpiryDate
      planName
      isCancelled
      commitmentTillDate
    }
  }
`;

export const scSubscriptionModulesBySystemClientIdRootQuery = gql`
  query scSubscriptionModulesBySystemClientId(
    $systemClientId: Int
    $sortedBy: [scSubscriptionModuleListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    scSubscriptionModulesBySystemClientId(
      systemClientId: $systemClientId
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      scSubscriptionModules {
        id
        scSubscriptionId
        planId
        moduleId
        isActive
        price
        moduleName
        moduleDisplayName
        lastInvoiceFromDate
        lastInvoiceToDate
        isAddonModule
      }
      rowCount
    }
  }
`;

export const scSubscriptionResourcesBySystemClientIdRootQuery = gql`
  query scSubscriptionResourcesBySystemClientId(
    $systemClientId: Int
    $sortedBy: [scSubscriptionResourcesListSortedByType]
    $limit: Int
    $offset: Int
  ) {
    scSubscriptionResourcesBySystemClientId(
      systemClientId: $systemClientId
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      scSubscriptionResources {
        id
        systemClientId
        scSubscriptionId
        planId
        includedCount
        maxCount
        balanceCount
        lastBalanceCountChangeTime
        lastInvoiceFromDate
        lastInvoiceToDate
        ratio
        resetCycle
        resourceType
        lastResetDatetime
        pricePerAdditionalUnit
        resourceName
        createdDatetime
        createdBy
        isActive
        isDelete
      }
      rowCount
    }
  }
`;

export const scSubscriptionPricelistBySystemClientIdRootQuery = gql`
  query scSubscriptionPricelistBySystemClientId(
    $systemClientId: Int
    $sortedBy: [scSubscriptionPricelistSortedByType]
    $limit: Int
    $offset: Int
  ) {
    scSubscriptionPricelistBySystemClientId(
      systemClientId: $systemClientId
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset
    ) {
      scSubscriptionPricelist {
        id
        scSubscriptionId
        planId
        signupPlanPricelistId
        renewal
        commitment
        price
        isDeleted
        isActive
      }
      rowCount
    }
  }
`;

export const getLastSystemClientSubscriptionByIdRootQuery = gql`
  query getLastSystemClientSubscriptionByIdRootQuery($systemClientId: Int) {
    getLastSystemClientSubscriptionById(systemClientId: $systemClientId) {
      id
      planId
      scSubscriptionPricelistId
      price
      renewal
      trialStartDate
      trialEndDate
      subscriptionStartdate
      subscriptionExpiryDate
      planName
      isCancelled
      commitmentTillDate
    }
  }
`;

export const extendTrialDateMutation = gql`
  mutation extendTrialDate($systemClientData: SystemClientStatusInputType) {
    extendTrialDate(systemClientData: $systemClientData) {
      id      
    }
  }
`;

export const extendDueDateMutation = gql`
  mutation extendDueDate($systemClientData: SystemClientStatusInputType) {
    extendDueDate(systemClientData: $systemClientData) {
      id      
    }
  }
`;

export const systemClientByPlanIdRootQuery = gql`
  query systemClientByPlanIdRootQuery($planId: Int
    $sortedBy: [ClientListSortedByPlanIdInputType]
    $limit: Int
    $offset: Int) {
    systemClientByPlanId(planId: $planId
      sortedBy: $sortedBy
      limit: $limit
      offset: $offset) {
        clientList {
          id
      clientName
      customerStatus
      price
      trialStartDate
      trialEndDate
      subscriptionStartDate
      subscriptionExpiryDate
      }
      rowCount
    }
  }
`;