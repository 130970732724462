import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Icon } from "@material-ui/core";

Object.assign(ReactTableDefaults, {
  defaultPageSize: 20,
  minRows: 3
});

class DraggableTable extends Component {
  constructor(props) {
    super(props);
    this.dragged = null;
    this.reorder = [];
    this.state = {
      trigger: 0,
      pageValue: 1
    };
  }
  mountEvents() {
    const headers = Array.prototype.slice.call(
      document.querySelectorAll(".draggable-header")
    );

    headers.forEach((header, i) => {
      header.setAttribute("draggable", true);
      //the dragged header
      header.ondragstart = (e) => {
        e.stopPropagation();
        this.dragged = i;
      };

      header.ondrag = (e) => e.stopPropagation;

      header.ondragend = (e) => {
        e.stopPropagation();
        //setTimeout(() => (this.dragged = null), 1000);
      };

      //the dropped header
      header.ondragover = (e) => {
        e.preventDefault();
      };

      header.ondrop = (e) => {
        e.preventDefault();
        const { target, dataTransfer } = e;
        this.reorder.push({ a: i, b: this.dragged });
        this.setState({ trigger: Math.random() });
      };
    });
  }
  componentDidMount() {
    this.mountEvents();
  }

  componentDidUpdate() {
    this.mountEvents();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.page != newProps.page) {
      this.setState({ pageValue: newProps.page + 1 });
    }
  }

  render() {
    const { rows, columns, sortable, page } = this.props;
    let cols = columns;
    // const cols = columns.map(col => ({
    //   ...col,
    //   Header: (
    //     <span
    //       className={
    //         col.sortable
    //           ? "draggable-header justify-between"
    //           : "draggable-header"
    //       }
    //     >
    //       <Icon className="text-18">drag_indicator</Icon> {col.HeaderText}{" "}
    //       {col.sortable && (
    //         <Icon className="sortableArrow">arrow_right_alt</Icon>
    //       )}
    //     </span>
    //   )
    // }));

    //run all reorder events
    // this.reorder.forEach(o => {
    //   return cols.splice(o.a, 0, cols.splice(o.b, 1)[0]);
    // });

    //render
    return (
      <div className="esr-table">
        <ReactTable
          {...this.props}
          // data={rows}
          columns={cols}
          sortable={sortable}
          loadingText=""
          ofText={"of"}
          pageText={"Page"}
          rowsText={"rows"}
          {...(page === undefined
            ? {}
            : {
                renderPageJump: ({
                  onChange,
                  value,
                  onBlur,
                  onKeyPress,
                  inputType,
                  pageJumpText
                }) => {
                  return (
                    <div className="-pageJump">
                      <input
                        aria-label={pageJumpText}
                        type={inputType}
                        onChange={(e) => {
                          if (e.target.value > 0) {
                            this.setState({
                              pageValue: parseInt(e.target.value)
                            });

                            onChange(e);
                          } else {
                            e.target.value = 1;

                            this.setState({
                              pageValue: parseInt(e.target.value)
                            });

                            onChange(e);
                          }
                        }}
                        value={this.state.pageValue}
                        onBlur={(e) => {
                          if (e.target.value > 0) {
                            onChange(e);
                          } else {
                            e.target.value = 1;

                            onChange(e);
                          }
                        }}
                        onKeyPress={onKeyPress}
                      />
                    </div>
                  );
                }
              })}
        />
      </div>
    );
  }
}

export default DraggableTable;
