import { combineReducers } from "redux";
import subscriptions from "../pages/subscriptions/subscriptionReducer";
import login from "../pages/login/loginReducer";
import sbizzlMaintenance from "../pages/sbizzlMaintenance/sbizzlMaintenanceReducereducer";


export default combineReducers({ subscriptions, login ,sbizzlMaintenance});

// export default function(store = {}, action) {
//   switch (action.type) {
//     case "LOAD_STORE":
//       return action.payload;
//     default:
//       return store;
//   }
// }
