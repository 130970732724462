import gql from "graphql-tag";

export const getSubscriptionPaginationQuery = gql`
query getSubscriptionPaginationQuery ($limit: Int, $offset: Int) {
  subscriptions (limit: $limit, offset: $offset) {
subscriptionsList {
    id
    subscriptionName
    subscriptionDisplayName
    description
    usersAllowed
    price
    bilingCycleId
    industryId
    clients {
      id
      subscriptionId
      systemClientId
      billingCycleId
      startDatetime
      systemClient
      {
        clientName
      }
    }
    subscriptionModules{
      moduleId
      module
      {
        moduleDisplayName
      }
    }
  }
rowCount
}
}

`;
export const getSubscriptionQuery = gql`
query getSubscriptionQuery ($id: Int)  {
  subscriptionById  (id: $id) {
    id
    subscriptionName
    subscriptionDisplayName
    description
    usersAllowed
    price
    bilingCycleId
    industryId
    clients {
      id
      subscriptionId
      systemClientId
      billingCycleId
      startDatetime
      systemClient
      {
        clientName
      }
    }
    subscriptionModules{
      moduleId
      module
      {
        moduleDisplayName
      }
      functionalities{
        functionalityDisplayName
      }
    }
  }
}
`;

export const addSubscriptionMutation = gql`
  mutation addSubscription($subscription: SubscriptionsInputType) {
    addSubscription(subscription: $subscription) {
      id
      subscriptionName
      subscriptionDisplayName
      description
      usersAllowed
      price
      bilingCycleId
     industryId
    }
  }
`;

export const editSubscriptionMutation = gql`
  mutation EditSubscription($subscription: SubscriptionsInputType) {
    editSubscription(subscription: $subscription) {
      id
      subscriptionName
      subscriptionDisplayName
      description
      usersAllowed
      price
      bilingCycleId
      industryId
      clients {
        id
        subscriptionId
        systemClientId
        billingCycleId
        startDatetime
      }
    }
  }
`;

export const deleteSubscriptionMutation = gql`
  mutation deleteSubscription($subscriptionId: Int) {
    deleteSubscription(subscriptionId: $subscriptionId) {
      id
      subscriptionName
      subscriptionDisplayName
      description
      usersAllowed
      price
      bilingCycleId
      industryId
    }
  }
`;

export const getbillingCycleRootQuery = gql`
query getbillingCycleRootQuery { 
  billingCycle {
          id
				billingCycleName          
      }
  }
  `;
