import React, { useEffect, useState } from "react";
import {
  Grid,
  DialogActions,
  Button,
  Select as MUISelect,
  ListItemText,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-apollo";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getLastSystemClientSubscriptionByIdRootQuery, extendDueDateMutation } from "./clientGqlQuery";
import AmountInput from "../common/AmountInput";
import { useFormik } from "formik";

const EditExtendDueDateModal = (props) => { 
  const {
    data: lastSystemClientSubscriptionData
  } = useQuery(getLastSystemClientSubscriptionByIdRootQuery, {
    variables: {
      systemClientId: parseInt(props.systemClientId),
    },
    fetchPolicy: "network-only",
  });

  const [extendDueDate] = useMutation(extendDueDateMutation);
  const [startDateOutlineLable, setStartDateOutlineLable] = useState(false);
  const [diffNo, setDiffNo] = useState(1);
  const [initForm, setInitForm] = useState({
    id: parseInt(props.systemClientId),
    extendedDate: new Date()
  });

  const formik = useFormik({
    initialValues: initForm,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      extendedDate: Yup.date()
        .nullable()
        .required("Please select extended date")
    }),
  });

  const handleSubmit = (values) => {
    let systemClientData = {};
    systemClientData.id = values.id;
    systemClientData.extendedDate = moment(values.extendedDate)
      // .tz("UTC")
      .format("YYYY-MM-DD");
      systemClientData.status = "ACTIVE"
    
      extendDueDate({
        variables: {
          systemClientData
        },
      })
        .then((item) => {
          if (props.onSuccess) {
            props.onSuccess(item);
          }
          props.handleClose();
        })
        .catch((err) => {
          console.log(err);
        });    
  };

  const { classes } = props;
  return (
    <form onSubmit={formik.handleSubmit}>      
        <div >
        <Grid container spacing={2} className={classes.rowContainer}>
              <Grid item xs={6}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25
                              }}
                            >
                              Plan Name
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400
                              }}
                            >                              
                              {lastSystemClientSubscriptionData && lastSystemClientSubscriptionData.getLastSystemClientSubscriptionById.planName}
                            </Typography>
                          }
                        />
                        </Grid>

                        <Grid item xs={6}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25
                              }}
                            >
                              Price
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400
                              }}
                            >                              
                              {lastSystemClientSubscriptionData && "$ " + lastSystemClientSubscriptionData.getLastSystemClientSubscriptionById.price}
                            </Typography>
                          }
                        />
                        </Grid>

                        <Grid item xs={6}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25
                              }}
                            >
                              Trial Start Date
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400
                              }}
                            >                              
                              {lastSystemClientSubscriptionData && lastSystemClientSubscriptionData.getLastSystemClientSubscriptionById.trialStartDate}
                            </Typography>
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>                      
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25
                              }}
                            >
                              Trial End Date
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400
                              }}
                            >                              
                              {lastSystemClientSubscriptionData && lastSystemClientSubscriptionData.getLastSystemClientSubscriptionById.trialEndDate}
                            </Typography>
                          }
                        />
                      </Grid>                      
          
                      <Grid item xs={6}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25
                              }}
                            >
                              Extended Till Date
                            </Typography>
                          }
                          secondary={
                            <div className="fullWidthDateTime dateComponent fromDate">
                            <DatePicker
                              selected={formik.values.extendedDate}
                              popperPlacement="top-bottom"
                              onChange={(extendedDate) => {
                                if(extendedDate){
                                let timeDifference = extendedDate.getTime() - new Date().getTime();
                                let diffNo = Math.round((timeDifference / (1000 * 60 * 60 * 24)) + 1);
                                formik.setFieldValue("extendedDate", extendedDate);
                                setDiffNo(diffNo);
                                } else {
                                  formik.setFieldValue("extendedDate", "");
                                  setDiffNo(0);
                                }
                              }}
                              error={
                                formik.touched.extendedDate && formik.errors.extendedDate
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.errors.extendedDate &&
                                formik.touched.extendedDate &&
                                formik.errors.extendedDate
                              }
                              // dateFormat="MMMM d, yyyy"
                              dateFormat="yyyy-MM-d"
                              className={
                                formik.errors.extendedDate
                                  ? "datePickerComponent errorDisplay"
                                  : "datePickerComponent"
                              }
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              onFocus={(e) => {
                                if (e) {
                                  e.target.select();
                                  setStartDateOutlineLable(true);
                                }
                              }}
                              onClickOutside={() => {
                                setStartDateOutlineLable(false);
                              }}
                              minDate={new Date()}                              
                              // showTimeSelect
                              // timeFormat="HH:mm"
                              // timeIntervals={15}
                            />
                            {formik.errors.extendedDate === "Please select extended date" && (
                              <span
                                style={{
                                  color: "red",
                                  display: "block",
                                  fontSize: 13,
                                  marginTop: 3,
                                }}
                              >
                                {formik.errors.extendedDate}
                              </span>
                            )}
                          </div>  
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>                      
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 15,
                                fontWeight: 500,
                                fontStretch: 1.25,
                                lineHeight: 1.25
                              }}
                            >
                              No Of Days
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 400
                              }}
                            >                              
                              {diffNo}
                            </Typography>
                          }
                        />
                      </Grid>
          </Grid>
        </div>      
      <DialogActions>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              formik.handleSubmit();
            }}
            color="primary"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </form>
  );
};

EditExtendDueDateModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const styles = (theme) => ({
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  rowContainer: {
    margin: "0px 0px 15px 0px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  table: {
    minWidth: 700,
  },
  textareaAutosize: {
    width: "100%",
    padding: "5px 10px",
    resize: "none",
  },
  textField: {
    width: "100%",
  },
  panel: {
    position: "fixed",
    width: 360,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    top: 0,
    height: "100%",
    minHeight: "100%",
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    padding: "55px 0px 0px",
    transform: "translate3d(360px,0,0)",
    // overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      transform: "translate3d(360px,0,0)",
      boxShadow: "none",
      "&.opened": {
        boxShadow: theme.shadows[5],
      },
    },
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    "&.opened": {
      transform: "translateX(0)",
    },
  },
});

export default withStyles(styles)(
  connect(mapStateToProps)(EditExtendDueDateModal)
);
