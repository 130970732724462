import React, { useState } from "react";
import {
  Grid,
  FormControlLabel,
  MenuItem,
  InputLabel,
  DialogContent,
  Checkbox,
  DialogActions,
  FormHelperText,
  Button,
  FormControl,
  Select as MUISelect,
} from "@material-ui/core";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import PropTypes from "prop-types";
import {
  getAllModulesRootQuery,
  addModuleToSignuPlanMutation,
} from "./signupPlansGQLQuery";
import AmountInput from "../common/AmountInput";
import { useFormik } from "formik";

const animatedComponents = makeAnimated();

const ModuleModal = (props) => {
  const {
    data: getAllModulesData,
    loading: getAllModulesLoading,
    refetch: getAllModulesRefetch,
    error: getAllModulesError,
    fetchMore: getAllModulesFetchMore,
  } = useQuery(getAllModulesRootQuery, {
    fetchPolicy: "network-only",
  });
  const [addModuleToSignuPlan] = useMutation(addModuleToSignuPlanMutation);
  const [initForm, setInitForm] = useState({
    moduleId: null,
    type: "",
    id: 0,
    price: 0.0,
  });
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const formik = useFormik({
    initialValues: initForm,
    validateOnBlur: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      moduleId: Yup.number()
        .min(1, "Please select module")
        .required("Please select module")
        .typeError("Please select module"),
      type: Yup.string().required("Please select type"),
      price: Yup.string()
        .min(0.01, "please enter at least one number")
        .max(14, "limit exceeds"),
    }),
  });

  const handleSubmit = (values) => {
    let moduledata = {
      id: values.id,
      signupPlanId: parseInt(props.signupPlanId),
      price: values.price,
      moduleId: values.moduleId,
      isAddonModule: values.type === "isAddon" ? true : false,
      reflectChange: values.reflectChange,
    };
    if (values.id > 0) {
      //code for Edit
    } else {
      addModuleToSignuPlan({
        variables: { signupModuledata: moduledata },
      })
        .then((item) => {
          if (
            item &&
            item.data &&
            item.data.addModuleToSignuPlan &&
            item.data.addModuleToSignuPlan.id
          ) {
            if (props.onSuccess) {
              props.onSuccess();
            }
            props.handleClose();
          }
          // console.log(item);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const { classes } = props;
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999 !important" : "white",
        color: "#333333",
      };
    },
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent className="popoverOverflow" style={{ paddingTop: 0 }}>
        <div>
          <Grid container spacing={2} style={{ marginTop: 4 }}>
            <Grid item xs={12} style={{ zIndex: 99999 }}>
              <div className="popoverFormDatePickerWraper">
                <FormControl
                  // style={{ display: "flex" }}
                  variant="outlined"
                  classes={{
                    paper: "popoverOverflow",
                  }}
                  className="w-full"
                  size="small"
                  required
                  error={formik.errors.moduleId && formik.touched.moduleId}
                >
                  <Select
                    onChange={(entry) => {
                      formik.setFieldValue("moduleId", entry ? entry.id : 0);
                      formik.setFieldValue("selectedModule", entry);
                      setSelectedModule(entry);
                      setModuleId(entry && entry.id);
                    }}
                    placeholder="Select module name *"
                    isClearable={true}
                    isSearchable={true}
                    value={selectedModule}
                    isMulti={false}
                    styles={colourStyles}
                    name="colors"
                    options={
                      getAllModulesData &&
                      getAllModulesData.getAllModules &&
                      getAllModulesData.getAllModules.length > 0 &&
                      getAllModulesData.getAllModules
                        .sort((a, b) =>
                          a.moduleName > b.moduleName
                            ? 1
                            : b.moduleName > a.moduleName
                            ? -1
                            : 0
                        )
                        .filter((alldata) => {
                          return !props.moduleIds.some((usedModules) => {
                            return alldata.id === usedModules.moduleId;
                          });
                        })
                        .map((item, idx) => {
                          item.label =
                            item.moduleName.charAt(0) +
                            item.moduleName
                              .slice(1)
                              .toLowerCase()
                              .replaceAll("_", " ");
                          return item;
                        })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {formik.errors.moduleId && formik.touched.moduleId ? (
                    <FormHelperText>
                      {formik.errors.moduleId &&
                        formik.touched.moduleId &&
                        formik.errors.moduleId}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} className="flex items-center">
              <Grid container direction="row" spacing={2}>
                <Grid className="MuiOutlinedInput-input" item xs={6}>
                  <FormControl
                    style={{ display: "flex" }}
                    variant="outlined"
                    className="w-full"
                    size="small"
                    required
                    error={formik.errors.type && formik.touched.type}
                  >
                    <InputLabel
                      id="demo-customized-select-label"
                      className={classes.lableOutline}
                    >
                      Select type
                    </InputLabel>
                    <MUISelect
                      value={formik.values.type}
                      className="display-flex w-full"
                      fullWidth={true}
                      variant="outlined"
                      label="type"
                      onChange={(e) => {
                        formik.setFieldValue("type", e.target.value);
                        formik.setFieldValue("price", 0);
                      }}
                    >
                      <MenuItem value="">Select Type</MenuItem>
                      <MenuItem value={"included"}>Included</MenuItem>
                      <MenuItem value={"isAddon"}>Is Add-On</MenuItem>
                    </MUISelect>
                    {formik.errors.type && formik.touched.type ? (
                      <FormHelperText>
                        {formik.errors.type &&
                          formik.touched.type &&
                          formik.errors.type}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                {formik.values.type === "isAddon" && (
                  <Grid item xs={6}>
                    <AmountInput
                      id="price"
                      name="price"
                      fixDigits={true}
                      label={"Price"}
                      value={formik.values.price}
                      onBlur={(value) => {
                        let val = value || 0;
                        formik.setFieldValue("price", val);
                        // this.setState({
                        //   setIsUserEnteredAmount: true,
                        // });
                      }}
                      helperText={
                        formik.errors.price &&
                        formik.touched.price &&
                        formik.errors.price
                      }
                      error={
                        formik.errors.price && formik.touched.price
                          ? true
                          : false
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <Grid style={{ marginTop: 15 }} item xs={10}>
                <FormControlLabel
                  className="chekBoxLabelActive"
                  control={
                    <Checkbox
                      name="Active"
                      onChange={(e) => {
                        formik.setFieldValue("reflectChange", e.target.checked);
                      }}
                      color="primary"
                      className="checkBoxprimary"
                    />
                  }
                  label={
                    "Add this changes to all the active" +
                    " " +
                    (props.planName || "") +
                    " " +
                    "plan customers."
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              // console.log("Caleed")
              formik.handleSubmit();
            }}
            color="primary"
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </form>
  );
};

ModuleModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};

const styles = (theme) => ({
  lableOutline: {
    zIndex: "9",
    background: "#fff",
    padding: "0px 5px 0px 6px",
    marginLeft: "-5px",
  },
  panel: {
    position: "fixed",
    width: 360,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    top: 0,
    height: "100%",
    minHeight: "100%",
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    padding: "55px 0px 0px",
    transform: "translate3d(360px,0,0)",
    // overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      transform: "translate3d(360px,0,0)",
      boxShadow: "none",
      "&.opened": {
        boxShadow: theme.shadows[5],
      },
    },
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    "&.opened": {
      transform: "translateX(0)",
    },
  },
});

export default withStyles(styles)(connect(mapStateToProps)(ModuleModal));
