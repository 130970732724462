import firebaseService from "../../lib/firebaseService";
import firebase from "firebase/app";

export const signInWithGoogle = () => {
  let provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account" });
  if (!firebaseService.auth) {
    console.warn(
      "Firebase Service didn't initialize, check your configuration"
    );
    return () => false;
  }
  // firebaseService.auth.signInWithRedirect(provider)
  firebase.auth().signInWithPopup(provider);
};
