import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@material-ui/core";

function AmountInput(props) {
  const { fixDigits } = props;

  const [value, setValue] = useState("");

  const timeout = useRef(null);

  useEffect(() => {
    // console.log("AmountInput", props.value, typeof props.value);
    setValue(format(props.value));
  }, [props.value, props.reRender]);

  const format = (val) => {
    if (val) {
      if (fixDigits) {
        return val.toFixed(2);
      } else {
        return val;
      }
    } else {
      return "";
    }
  };

  const selectOnFocus = (event) => {
    event.preventDefault();
    const { target } = event;

    timeout.current = setTimeout(() => {
      const valLen = target.value.length;
      if (valLen) {
        target.focus();
        target.setSelectionRange(0, valLen);
      }
    }, 100);
  };

  return (
    <TextField
      //   name={props.name}
      className="w-full"
      variant="outlined"
      size="small"
      label={props.label}
      required={props.required}
      inputProps={{
        style: { textAlign: "right" }
      }}
      autoFocus={props.autoFocus}
      onFocus={selectOnFocus}
      value={value}
      onChange={(e) => {
        setValue(e.target.value.replace(/[^0-9.]/g, ""));
      }}
      onBlur={(e) => {
        // console.log("1", value);
        let val = parseFloat(value) || 0;
        val = props.maxValue && val > props.maxValue ? props.value : val;
        // console.log("2", val);
        val = format(val);
        // console.log("3", val);
        setValue(val);

        if (props.onBlur) {
          props.onBlur(val ? parseFloat(val) : 0);
        }
      }}
      error={props.error}
      helperText={props.helperText}
    />
  );
}

export default AmountInput;
