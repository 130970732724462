import prodConfig from "./prod";
import uatConfig from "./uat";
import devConfig from "./dev";

export default (process.env.NODE_ENV === "development"
  ? devConfig
  : prodConfig);

//enable to test live data on local
// export default prodConfig;
// export default uatConfig;
