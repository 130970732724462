/* eslint-disable no-console */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import DraggableTable from "../common/DraggableTable";
import queryHistory from"../../lib/queryHistroy";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// import {
//   // getAllSubscriptions,
//   addNewSubscription,
//   editSubscription,
//   deleteSubscription
// } from "./subscriptionActions";

import { graphql } from "react-apollo";
import { compose } from "recompose";
import {
  getSubscriptionPaginationQuery,
  deleteSubscriptionMutation,
  addSubscriptionMutation,
  editSubscriptionMutation,
  getbillingCycleRootQuery
} from "./subscriptionGqlQuery";
import {getIndustriesRootQuery} from "../industry/industryGQLQuery";
import {CircularProgress,Grid, ListItemText, Menu,Table,TableBody,TableCell,TableContainer,TableRow,Typography} from "@material-ui/core";
import {IconButton,Icon,FormHelperText} from "@material-ui/core";
import ConfirmDialog from "../common/ConfirmDialog";
import SubscriptionsDetails from "./SubscriptionsDetails";
const defaultPageSize = 20;
const defaultPage = 1;

const styles = theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  table: {
    minWidth: 700
  }
});

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.refReactTable = {};

    let historyVars =
      queryHistory.get(props, "getSubscriptionPaginationQuery") ||
      {};

    this.state = { open: false,
      subscriptionList:[],
      queryDidLoad: false,
      openDialog:false,
      clientName:[],
      defaultPage: defaultPage,
      pages: defaultPage,
      currentPage: historyVars.offset ? historyVars.offset - 1 : 0,
      initForm:{
        id: 0,
        industryId:"",
        subscriptionName: "",
        subscriptionDisplayName: "",
        description:"",
        price:"",
        users:"",
        billingCycle:"",
      },
      pageSize: historyVars.limit ? historyVars.limit - 1 : defaultPageSize,
      columns: [
        {
          Header:" Name",
          Cell: (props) => {
            return (
              <>
                  <span>
                 { props.original.subscriptionName}
                  </span>
              </>
            );
          }
        },
        {
          Header:" Subscription display name",
          Cell: (props) => {
            return (
              <>
                  <span>
                 { props.original.subscriptionDisplayName}
                  </span>
              </>
            );
          }
        },
        {
          Header:" Price",
          Cell: (props) => {
            return (
              <>
                  <span>
                 { props.original.price}
                  </span>
              </>
            );
          }
        },
        {
          Header:" Biling cycle",
          Cell: (props) => {
            // console.log("propsorginal",props.original);
            return (
              <>
                  <span>
                 { props.original.bilingCycleId}
                  </span>
              </>
            );
          }
        },
        {
          Header:" Users",
          Cell: (props) => {
            return (
              <>
                  <span>
                 { props.original.usersAllowed}
                  </span>
              </>
            );
          }
        },
        {
          Header:" Number of clients",
          Cell: (props) => {
            return (
              <>
                  <div
                   style={{ textAlign: "center" }}
                   
             fontSize="small">
               <Button
               variant="text"
               size="medium"
               disableRipple={true}
               disableElevation={true}
               disabled={props.original.clients.length === 0}
               style={{
                 backgroundColor:"transparent"
               }}
               onClick={(ev) => {
                ev.stopPropagation();
                  this.handleShowMore(props.original.clients)}}
               >
                 { props.original.clients ? props.original.clients.length : 0}
                 </Button>
                 </div>
              </>
            );
          }
        },
        {
          Header:"Actions",
          Cell: (props) => {
            return (
              <>
                  <span>
                 
                  <ListItemIcon onClick={(ev) => {
                    ev.stopPropagation()}}>
                  <ConfirmDialog
                disabled={props.original.clients.length ? true : false}
                   title={"Are you sure you want to delete this record?"}
                                        firstButton="Cancel"
                                        secondButton="Delete"
                                        onConfirm={this.deleteSubscription.bind(this, props.original.id)}
                                        onReject={() => {}}
                                      >
                                        
                                        <IconButton
                                          size="small"
                                        >
                                          
                                          <Icon>
                                            delete
                                          </Icon>
                                        </IconButton>
                                      </ConfirmDialog>

                          <Edit onClick={this.editSubscription.bind(this, props.original)} />
                        </ListItemIcon>
                  </span>
              </>
            );
          }
        },
       ], };
      }

  componentWillMount() {
    // this.props.getAllSubscriptions();
  }
  handleShowMore = (e) => {
    this.setState({
       clientName:e,
      openDialog: true,    
      titleName: "Module Name",
    });
  };
  redirectToDetailPage = (Id)=>{
    const { history } = this.props;
    history.push(
      `/admin/subscriptions/SubscriptionsDetails/${Id}`
  )
  }
  
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
     initForm:{ 
       id: 0,
      subscriptionName: "",
      subscriptionDisplayName:"",
      price: "",
      users: "",
      description: "",
      billingCycle: "",
      industryId:""
    },
            open: false,
            openDialog:false
    });
  };

  onInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit =  (values) => {
    let Data = {
      id: values.id,
      subscriptionName: values.subscriptionName,
      subscriptionDisplayName:values.subscriptionDisplayName,
      description: values.description,
      industryId:values.industryId,
      price: parseFloat(values.price),
      usersAllowed: parseInt(values.users),
      bilingCycleId: values.billingCycle
    };
    if (values.id > 0) {
       this.props
        .editSubscriptionMutation({
          variables: {
            subscription: Data
          },
          refetchQueries: [{ query: getSubscriptionPaginationQuery }]
        })
        .then(item => {
          console.log(item);
          })
        .catch(err => {
          console.log(err);
        });
    } else {
       this.props
        .addSubscriptionMutation({
          variables: {
            subscription: Data
          },
          refetchQueries: [{ query: getSubscriptionPaginationQuery }]
        })
        .then(item => {
          console.log(item);
        })
        .catch(err => {
          console.log(err);
        });
    }
    this.handleClose();
  };

  editSubscription = data => {
    this.setState(  {
      initForm:{
      id: data.id,
      subscriptionName: data.subscriptionName,
      subscriptionDisplayName: data.subscriptionDisplayName,
      description: data.description,
      price: data.price,
      users: data.usersAllowed,
      billingCycle: data.bilingCycleId,
      industryId:data.industryId,
      },
      open: true
    });
  };
  deleteSubscription = id => {
    //delete gql
    this.props
      .deleteSubscriptionMutation({
        variables: {
          subscriptionId: id
        },
        refetchQueries: [{ query: getSubscriptionPaginationQuery }]
      })
      .then(item => {
        console.log(item);
      })
      .catch(err => {
        console.log(err);
      });
  };
  fetchData = async (state, instance) => {
    this.refReactTable = instance;
    this.getData(state.pageSize, state.page + 1);
  };
  
  getData = async (pageSize, page) => {
    if (this.props.getSubscriptionPaginationQuery.loading) {
      return;
    }
  
    let queryVariables = {
      limit: pageSize,
      offset: page,
    };
    // queryHistory.set(
    //   this.props,
    //   "getSubscriptionPaginationQuery",
    //   queryVariables
    // );
    this.props.getSubscriptionPaginationQuery
      .refetch(queryVariables)
      .then((i) => {
       
        this.setState({
          subscriptionList: !this.props.getSubscriptionPaginationQuery
            .loading
            ? this.props.getSubscriptionPaginationQuery
                .subscriptions.subscriptionsList
            : [],
            pages: this.props.getSubscriptionPaginationQuery
            .subscriptions
            ? Math.ceil(
                this.props.getSubscriptionPaginationQuery
                  .subscriptions.rowCount /
                  pageSize
              )
            : 1,
          pageSize: pageSize,
          loading: false
        });
      });
  };
  
  initializeScreen = () => {
    if (
      !this.props.getSubscriptionPaginationQuery.loading &&
      !this.state.queryDidLoad
    ) {
      this.setState(
        {
          pages: this.props.getSubscriptionPaginationQuery
          .subscriptions
          ? Math.ceil(
              this.props.getSubscriptionPaginationQuery
                .subscriptions.rowCount /
                defaultPageSize
            )
          : 1,
          subscriptionList: this.props.getSubscriptionPaginationQuery.subscriptions
            ? this.props.getSubscriptionPaginationQuery.subscriptions.subscriptionsList
            : [],
            industryId:this.props.getIndustriesRootQuery.industry
            ? this.props.getIndustriesRootQuery.industry.id
            :[]
        },
        () => {
          this.setState({
            queryDidLoad: true
          });
        }
      );
    }
  };
  render() {
    var height = window.innerHeight - 155;
    this.initializeScreen();
    const { classes } = this.props;
    return (
      <div className="reactTableWraper">
              <DraggableTable
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                pages={this.state.pages} // Display the total number of pages
                loading={
                  this.props.getSubscriptionPaginationQuery
                    .loading
                } // Display the loading overlay when we need it
                onFetchData={this.fetchData} // Request new data when things change
                 data={this.state.subscriptionList}
                expanderDefaults={{ width: 0 }}
                columns={this.state.columns}
                resizable={true}
                noDataText="No Record Found"
                minRows={1}
                pageSizeOptions={[20, 25, 50, 100]}
                defaultPageSize={this.state.pageSize}
                pageSize={this.state.pageSize}
                page={this.state.currentPage}
                onPageChange={(page) => {
                  this.setState({ currentPage: page });
                }}
                onPageSizeChange={(sz) => {
                  this.setState({
                    currentPage: 0,
                    pageSize: sz
                  });
                }}
                multiSort={true}
                collapseOnDataChange={false}
                style={{
                  maxHeight: height,
                  cursor: "pointer"
                }}
                getTrProps={(state, rowInfo, column, instance,item) => {
                  return {
                    onClick: (ev) => {  
                      ev.stopPropagation();
                       this.redirectToDetailPage(rowInfo.original.id)
                    }
                    
                  };
                }}
              />
              <FormControl>
                      <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Subscription</DialogTitle>
      <Formik
      initialValues={this.state.initForm}
      validateOnBlur={false}
      onSubmit={(values) => {
        this.handleSubmit(values);
      }}
      onReset={() => {}}
      validationSchema={Yup.object().shape({
        id: Yup.number(),
        industryId:Yup.number()
        .required("Industry Name is Required"),
        subscriptionName: Yup.string()
        .required("Subscription Name is Required"),
        subscriptionDisplayName: Yup.string()
        .required("Subscription Display Name is Required"),
        description:Yup.string(),
        price: Yup.number()
        .required("Price Required"),
        users:Yup.number()
        .required("Users is Required"),
        billingCycle:Yup.number()
        .required("Billing Cycle is Required"),
      })}
      enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setValues
          } = props;
          return (
                <Form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="subscriptionName"
              label="Subscription name"
              type="text"
              fullWidth
              value={values.subscriptionName}
              name="subscriptionName"
              onChange={handleChange}
              helperText={
                touched.subscriptionName && touched.subscriptionName
                  ? errors.subscriptionName
                  : ""
              }
              error={
                touched.subscriptionName &&  errors.subscriptionName
            }
            />
            <TextField
              margin="dense"
              id="subscriptionDisplayName"
              label="Subscription display name"
              type="text"
              fullWidth
              value={values.subscriptionDisplayName}
              name="subscriptionDisplayName"
              onChange={handleChange}
              helperText={
                touched.subscriptionDisplayName && touched.subscriptionDisplayName
                  ? errors.subscriptionDisplayName
                  : ""
              }
              error={
                touched.subscriptionDisplayName &&  errors.subscriptionDisplayName
            }
            />
             <TextField
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              value={values.description}
              name="description"
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              id="price"
              label="Price"
              type="number"
              fullWidth
              value={values.price}
              name="price"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              helperText={
                touched.price && touched.price
                  ? errors.price
                  : ""
              }
              error={
                touched.price &&  errors.price
            }
            />
            <TextField
              margin="dense"
              id="users"
              label="Users"
              type="number"
              fullWidth
              value={values.users}
              name="users"
              onChange={handleChange}
              helperText={
                touched.users && touched.users
                  ? errors.users
                  : ""
              }
              error={
                touched.users &&  errors.users
            }
            />
            <FormControl
            fullWidth
            style={{
              marginTop:2
            }}
            error={
              errors.billingCycle && touched.billingCycle ? true : false
            }
            required
           > 
        <InputLabel>Renewal cycle</InputLabel>
            <Select
                fullWidth
                value={values.billingCycle}
                    onChange={(event) => {
                        setFieldValue("billingCycle", event.target.value);}}
                        error={
                          errors.billingCycle && touched.billingCycle ? true : false
                        } >
                      {!this.props.getbillingCycleRootQuery.loading &&
                          this.props.getbillingCycleRootQuery.billingCycle.length >
                            0 &&
                          this.props.getbillingCycleRootQuery.billingCycle.map(
                            (step, index) => {
                              return (
                                <MenuItem
                                  value={step.id}
                                  key={index}
                                  name={step.billingCycleName}
                                >
                                  {step.billingCycleName}
                                </MenuItem>
                              );
                            }
                          )}
                          </Select>
                          {errors.billingCycle && touched.billingCycle ? (
                        <FormHelperText>
                          {errors.billingCycle &&
                            touched.billingCycle &&
                            errors.billingCycle}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
            </FormControl>
            <FormControl
            fullWidth
            style={{
              marginTop:2
            }}
            error={
              errors.industryId && touched.industryId ? true : false
            }
            required
           > 
        <InputLabel>Industry name</InputLabel>
                <Select
                fullWidth
                    value={values.industryId}
                    onChange={(event) => {
                        setFieldValue("industryId", event.target.value);}}
                        error={
                          errors.industryId && touched.industryId ? true : false
                        }
                        >
                      {!this.props.getIndustriesRootQuery.loading &&
                          this.props.getIndustriesRootQuery.industry.length >
                            0 &&
                          this.props.getIndustriesRootQuery.industry.map(
                            (step, index) => {
                              return (
                                <MenuItem
                                  value={step.id}
                                  key={index}
                                  name={step.industryName}
                                >
                                  {step.industryName}
                                </MenuItem>
                              );
                            }
                          )}
                          </Select>
                          {errors.industryId && touched.industryId ? (
                        <FormHelperText>
                          {errors.industryId &&
                            touched.industryId &&
                            errors.industryId}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                 </FormControl>
          </DialogContent>
          <DialogActions>
            <Button  onClick={() => {
                      this.handleClose();
                    }} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
          </Form>
                );
              }}
            </Formik>
          </Dialog>
          <Dialog
          open={this.state.openDialog}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
>
<DialogTitle id="form-dialog-title">Clients Name</DialogTitle>
<DialogContent style={{overflow: "hidden", width:"400px",overflowY:"auto"}}>
  <TableContainer>
  {
this.state.clientName.map(i =>    

      i.systemClient.map(j => {
       return (
      
     <Table container spacing={2}>
                    {
                   j && 
                   j.clientName && 
                    <TableCell item xs={12}
                    >
                                  <Typography
                                  >
                                   { j.clientName || ""}
                                  </Typography> 
                      </TableCell>
                   
                     }  
                     
  </Table>   
       )
    }))}
    </TableContainer>
</DialogContent>
          </Dialog>
        </FormControl>
        <Fab
          onClick={this.handleClickOpen}
          className={classes.fab}
          color="primary"
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
  
}

Subscriptions.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ subscriptions }) => {
  return { subscriptions };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(
    compose(
      graphql(getSubscriptionPaginationQuery, { name: "getSubscriptionPaginationQuery" }),
      graphql(getbillingCycleRootQuery, { name: "getbillingCycleRootQuery" }),
      graphql(getIndustriesRootQuery, { name: "getIndustriesRootQuery" }),
      graphql(deleteSubscriptionMutation, {
        name: "deleteSubscriptionMutation"
      }),
      graphql(addSubscriptionMutation, { name: "addSubscriptionMutation" }),
      graphql(editSubscriptionMutation, { name: "editSubscriptionMutation" })
    )(Subscriptions)
  )
);
