
var config = {
  apiKey: "AIzaSyBCvv_floyulwjHukuwqN6JddMKjUlmkaY",
  authDomain: "sbizzl-app.firebaseapp.com",
  databaseURL: "https://sbizzl-app.firebaseio.com",
  projectId: "sbizzl-app",
  storageBucket: "sbizzl-app.appspot.com",
  messagingSenderId: "175236807638",
  appId: "1:175236807638:web:8a82295ddfcc8490",
  measurementId: "G-NFMZ3N1S6S"
};

export default config;
