import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  List,
  ListItemText,
  ListItem,
  Paper,
  FormControlLabel
} from "@material-ui/core";
import { getSubscriptionQuery } from "./subscriptionGqlQuery";
import "react-table/react-table.css";
import moment from "moment";
import DraggableTable from "../common/DraggableTable";
class SubscriptionsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryDidLoad: false,
      subscriptionsList:[]
      }}
      
      
    initializeScreen = () => {
      if (
        !this.props.getSubscriptionQuery.loading &&
        !this.state.queryDidLoad
      ) {
        this.setState(
          {
            subscriptionsList: this.props.getSubscriptionQuery
              ? this.props.getSubscriptionQuery.subscriptionById.subscriptionModules
              : []
          },
          () => {
            this.setState({
              queryDidLoad: true
            });
          }
        );
      }
    };

    render() {
    var height = window.innerHeight - 155;
        this.initializeScreen();

    const { classes } = this.props;
    return (
            <div    className={(
              classes.cardWrapper
            )}> 
              {this.state.subscriptionsList.map((i)=>{
               return <Card
               className={classes.cardlistStyle}
             >
               <div className={classes.cardHeader}>
                   {
                     i.module.moduleDisplayName
                   }
               </div>
               {i.functionalities.map((fData) => {
                 return  <div
                     className={classes.cardContent}
                   >
                     <div className={classes.cardContentLeft}>
                     {
                           fData.functionalityDisplayName
                         }
                     </div>
                     </div>
                 })}            
                     </Card>
                     })}
            </div>
    );
  }
}
const styles = (theme) => ({
  cardWrapper: {
    overflowX: "auto",
    padding: "6px",
    background: "#fbfbfb",
    margin: "0px 10px",
    "& label.Mui-disabled": {
      cursor: "default"
    }
  },
  cardlistStyle: {
    display: "flex",
    marginBottom: "15px",
    flex: "1 1",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    "&:last-child": {
      marginBottom: "0px"
    }
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    background: "#f5f8fa",
    padding: "5px 12px",
    borderBottom: "1px solid rgb(223, 227, 235)",
    "& label.MuiFormControlLabel-root": {
      marginRight: "0px"
    },
    "& span.MuiButtonBase-root": {
      padding: "4px 9px",
      backgroundColor: "transparent !important"
    },
    "& span.MuiTypography-root": {
      fontWeight: "700",
      textTransform: "uppercase"
    },
    "& span.MuiTouchRipple-root": {
      display: "none"
    }
  },
  cardContent: {
    display: "flex",
    padding: "4px 12px",
    flex: "1 1",
    alignItems: "center",
    borderBottom: "1px solid rgb(223, 227, 235)",
    "& span.MuiButtonBase-root": {
      padding: "4px 9px",
      backgroundColor: "transparent !important"
    },
    "& span.MuiTouchRipple-root": {
      display: "none"
    }
  },
  cardContentLeft: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "0px 10px 0px 0px",
    "& label.MuiFormControlLabel-root": {
      marginRight: "0px",
      fontWeight: "600"
    },
    "& span.MuiTypography-body1": {
      fontWeight: "600"
    }
  },
  cardContentRight: {
    display: "flex",
    flex: 3,
    flexWrap: "wrap",
    padding: "0px 10px",
    flexDirection: "column",
    "& span.MuiTypography-body1": {
      fontWeight: "600"
    }
  },
  cardWrapperHeader: {
    // display: "flex",
    // justifyContent: "flex-end",
    padding: "8px 16px",
    margin: "5px 0px 0px 0px"
  },
  cardWrapperBox: {
    display: "flex",
    "& div.MuiFormControl-root": {
      marginRight: "10px"
    },
    "& button": {
      height: "38px"
    }
  },
});
SubscriptionsDetails.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = ({  }) => {
  return {  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
    )(
compose(graphql(getSubscriptionQuery, { name: "getSubscriptionQuery" ,
options: (props) => {
  return {
    variables: {
      id: parseInt(props.match.params.Id)
    },
    fetchPolicy: "network-only"
  };
}})
 ) (SubscriptionsDetails)
  )
);